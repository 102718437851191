import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const IconWrapperStyled = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-color: ${({ theme }) => theme.colors.feedback.error[90]};
  padding: ${({ theme }) => theme.spacings.xs4};
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`

export const TextAlertStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  font-weight: 700 !important;
`
