import { useMemo } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import {
  PermissionEnum,
  useAdminPermissions,
} from 'src/utils/hooks/useAdminPermissions'
import { useIsSimpleAssignmentEnabled } from 'src/utils/hooks/useIsSimpleAssignmentEnabled'

import { FlashBenefitEmptyStateTab } from './Tabs/FlashBenefitEmptyStateTab'
import { FlashBenefitTab } from './Tabs/FlashBenefitTab'
import { useFlashBenefitTab } from './Tabs/FlashBenefitTab/useFlashBenefitTab'

const BASE_PATH = '/simple-assignment'

export function useSimpleAssignment() {
  const { isSimpleAssignmentEnabled, isLoadingAssignmentEnabled } =
    useIsSimpleAssignmentEnabled()
  const match = useMatch(`${BASE_PATH}/:key`)
  const navigate = useNavigate()

  const { navigateToNewAssignment } = useFlashBenefitTab()

  const { hasPermission: hasSimpleAssignmentPermission } = useAdminPermissions({
    permission: PermissionEnum.BENEFITS_SIMPLE_ASSIGNMENT_VIEW,
  })

  const canShowFlashBenefitsTab = useMemo(
    () => hasSimpleAssignmentPermission && isSimpleAssignmentEnabled,
    [hasSimpleAssignmentPermission, isSimpleAssignmentEnabled],
  )

  const tabParams = useMemo(() => {
    const AVAILABLE_TABS = canShowFlashBenefitsTab ? ['flash-benefits'] : []

    const currentTabIndex = AVAILABLE_TABS.findIndex(
      (route) => route === match?.params.key,
    )

    return {
      currentTab: Math.max(0, currentTabIndex),
      setParams: (index: number) =>
        navigate(`${BASE_PATH}/${AVAILABLE_TABS[index]}`),
    }
  }, [canShowFlashBenefitsTab, navigate, match])

  const getTabsDropdown = useMemo(() => {
    const tabLabel = canShowFlashBenefitsTab
      ? 'Benefícios Flash'
      : 'Benefícios Flash (em breve)'

    const dropdownItems = [
      {
        label: tabLabel,
        onClick: navigateToNewAssignment,
      },
    ]

    const tabItems = [
      {
        label: tabLabel,
        component: canShowFlashBenefitsTab ? (
          <FlashBenefitTab />
        ) : (
          <FlashBenefitEmptyStateTab />
        ),
      },
    ]

    return { tabItems, dropdownItems }
  }, [canShowFlashBenefitsTab, navigateToNewAssignment])

  return {
    isLoadingAssignmentEnabled,
    canShowFlashBenefitsTab,
    tabParams,
    getTabsDropdown,
    navigateToNewAssignment,
  }
}
