import { ReactNode } from 'react'

import { NavFooterContainerStyled, WrapperStyled } from './styled'

export type FooterProps = {
  leftPositionElements: ReactNode[]
  rightPositionElements: ReactNode[]
}

export const NavFooter = ({
  leftPositionElements,
  rightPositionElements,
}: FooterProps) => {
  return (
    <NavFooterContainerStyled>
      <WrapperStyled>{leftPositionElements}</WrapperStyled>
      <WrapperStyled>{rightPositionElements}</WrapperStyled>
    </NavFooterContainerStyled>
  )
}
