import { Tag } from '@flash-tecnologia/hros-web-ui-v2'

import { WrapperTagStyled } from './styles'

type HeaderNameProps = {
  selectedTotal: number
  total: number
}

export function HeaderName({ selectedTotal, total }: HeaderNameProps) {
  const showTag = selectedTotal > 1

  return (
    <>
      {showTag ? (
        <WrapperTagStyled>
          <Tag variant="pink">
            {selectedTotal} de {total} selecionados
          </Tag>
        </WrapperTagStyled>
      ) : (
        'Nome'
      )}
    </>
  )
}
