import { useCallback, useEffect } from 'react'
import { trpc } from 'src/api/client'
import { useCurrencyInput } from 'src/utils/hooks/useCurrencyInput'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'
import { useToast } from 'src/utils/hooks/useToast'

import { useModalBenefitAssignments } from '../context'

export const useModalEditBalance = () => {
  const { trackPage, trackEvent } = useEventTracking()
  const { toastSuccess, toastError } = useToast()
  const { assignmentId, balanceInCents, benefitName, onClose, onCancel } =
    useModalBenefitAssignments()
  const { getBenefitAssignments } = trpc.useContext()
  const updateValueAssignmentByIdMutation =
    trpc.updateValueAssignmentById.useMutation()

  const { handleInputChange, valueInCents, valueInCurrency } =
    useCurrencyInput(balanceInCents)

  const handleConfirm = useCallback(() => {
    trackEvent({ name: 'FlashOS_SimpleAssignment_EditAssign_Confirm_Click' })

    const value = valueInCents / 100

    updateValueAssignmentByIdMutation.mutate(
      {
        assignmentId,
        value,
      },
      {
        onSuccess() {
          onClose()

          getBenefitAssignments.invalidate()

          toastSuccess({ title: 'Saldo editado com sucesso!' })
        },
        onError() {
          toastError({
            title: 'Erro ao editar o saldo.',
            description: 'Tente novamente mais tarde.',
          })
        },
      },
    )
  }, [
    assignmentId,
    getBenefitAssignments,
    onClose,
    toastError,
    toastSuccess,
    trackEvent,
    updateValueAssignmentByIdMutation,
    valueInCents,
  ])

  useEffect(() => {
    trackPage({ name: 'FlashOS_SimpleAssignment_EditAssign_View' })
  }, [trackPage])

  return {
    benefitName,
    valueInCurrency,
    isLoading: updateValueAssignmentByIdMutation.isLoading,
    handleInputChange,
    handleConfirm,
    onCancel,
  }
}
