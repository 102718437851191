import { Button, Icons, Modal } from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode } from 'react'

import {
  ButtonCancelStyled,
  ContainerStyled,
  IconWrapperStyled,
} from './styles'

type RootProps = {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export function Root({ children, isOpen, onClose, onConfirm }: RootProps) {
  return (
    <Modal.Root open={isOpen} onClose={onClose}>
      <>
        <ContainerStyled>
          <IconWrapperStyled>
            <Icons name="IconAlertCircle" size={64} />
          </IconWrapperStyled>

          {children}
        </ContainerStyled>

        <Modal.Footer>
          <ButtonCancelStyled variant="secondary" onClick={onClose}>
            Cancelar
          </ButtonCancelStyled>

          <Button variant="secondary-error" size="medium" onClick={onConfirm}>
            Confirmar
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}
