import {
  Divider,
  SearchField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { RadioOptionCard } from 'src/components/RadioOptionCard'
import * as Table from 'src/components/Table'

import {
  ChosenBenefit,
  ChosenEmployee,
  TypeValue,
} from '../../useNewAssignmentStore'
import { useConfigColumns } from './ConfigColumns'
import { Header } from './Header'
import {
  AccordionStyled,
  ContainerStyled,
  TextFieldStyled,
  WrapperContentAmountForAll,
  WrapperSearchStyled,
  WrapperTableStyled,
} from './styles'
import { useBenefitAccordion } from './useBenefitAccordion'

type AccordionProps = {
  benefit: ChosenBenefit
  employees: ChosenEmployee[]
  defaultExpanded: boolean
}

export function BenefitAccordion({
  benefit,
  employees,
  defaultExpanded,
}: AccordionProps) {
  const {
    handleInputChange,
    clickedRadio,
    handleSearchTermChange,
    handleSearchTermFocus,
    valueInCurrency,
    filteredEmployees,
    selectedEmployees,
    setSelectedEmployees,
  } = useBenefitAccordion(benefit.id, benefit.typeValue, employees)

  const timeUnitLabel = useMemo(
    () => `Saldo por ${benefit.depositTimeUnit === 'day' ? 'dia' : 'mês'}`,
    [benefit.depositTimeUnit],
  )

  const { columns } = useConfigColumns({
    benefitId: benefit.id,
    employees: filteredEmployees,
    timeUnitLabel,
    selectedEmployees,
    setSelectedEmployees,
  })

  return (
    <AccordionStyled
      variant="default"
      isInvalid={benefit.isInvalid ?? false}
      defaultExpanded={defaultExpanded}
      customHeader={
        <Header
          id={benefit.id}
          benefitName={benefit.name}
          benefitImage={benefit.emoji}
        />
      }
    >
      <ContainerStyled data-accordion-invalid={benefit.isInvalid ?? false}>
        <RadioOptionCard.Root
          checked={benefit.typeValue === TypeValue.SET_SAME_AMOUNT_FOR_ALL}
          onClick={() => clickedRadio(TypeValue.SET_SAME_AMOUNT_FOR_ALL)}
        >
          <RadioOptionCard.Title>
            Definir o mesmo saldo para todas as pessoas da atribuição
          </RadioOptionCard.Title>

          <RadioOptionCard.Description>
            Escolhendo essa opção, o mesmo valor será aplicado a todas as
            pessoas selecionadas para este benefício
          </RadioOptionCard.Description>
        </RadioOptionCard.Root>

        <RadioOptionCard.Root
          checked={benefit.typeValue === TypeValue.SET_INDIVIDUAL_AMOUNTS}
          onClick={() => clickedRadio(TypeValue.SET_INDIVIDUAL_AMOUNTS)}
        >
          <RadioOptionCard.Title>
            Definir saldo individualmente para cada pessoa da atribuição
          </RadioOptionCard.Title>

          <RadioOptionCard.Description>
            Escolhendo essa opção, você poderá distribuir valores diferentes
            para cada pessoa selecionada para este benefício
          </RadioOptionCard.Description>
        </RadioOptionCard.Root>

        <Divider orientation="horizontal" />

        {benefit.typeValue === TypeValue.SET_SAME_AMOUNT_FOR_ALL && (
          <WrapperContentAmountForAll>
            <Typography variant="body3">
              Definir saldo para todas as pessoas selecionadas
            </Typography>

            <TextFieldStyled
              label={timeUnitLabel}
              value={valueInCurrency}
              onChange={handleInputChange}
            />
          </WrapperContentAmountForAll>
        )}

        {benefit.typeValue === TypeValue.SET_INDIVIDUAL_AMOUNTS && (
          <>
            <WrapperSearchStyled>
              <Typography variant="body3">
                Definir saldo individualmente
              </Typography>

              <SearchField
                label="Buscar por pessoas"
                onFocus={handleSearchTermFocus}
                onChange={handleSearchTermChange}
              />
            </WrapperSearchStyled>

            <WrapperTableStyled>
              <Table.Root isLoading={false}>
                <Table.Grid
                  data={filteredEmployees}
                  rowIdKey="id"
                  columns={columns}
                  pageSize={filteredEmployees.length}
                  emptyStateMessage="Pessoa não encontrada!"
                />
              </Table.Root>
            </WrapperTableStyled>
          </>
        )}
      </ContainerStyled>
    </AccordionStyled>
  )
}
