import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerSkeletonStyled = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  row-gap: ${({ theme }) => theme.spacings.s};
`

export const SkeletonStyled = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borders.radius.s};
`

export const BoxFilterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.xs3};
`
