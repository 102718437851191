import { ReactNode } from 'react'

import { ContentGridContainerStyled } from './styled'

type ContentProps = {
  children: ReactNode
}

export const Content = ({ children }: ContentProps) => {
  return <ContentGridContainerStyled>{children}</ContentGridContainerStyled>
}
