import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.xs2};
`

export const WrapperAmountStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.xs4};

  width: 148px;

  input {
    width: 100%;

    border-bottom: 2px solid ${({ theme }) => theme.colors.neutral50};

    outline: 0;

    color: ${({ theme }) => theme.colors.neutral40};

    font-weight: 600;

    background-color: transparent;
  }

  p {
    color: ${({ theme }) => theme.colors.neutral40};

    font-weight: 600;
  }
`

export const WrapperMessageStyled = styled(Typography)`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacings.xs5};

  color: ${({ theme }) => theme.colors.neutral50};

  font-weight: 600;
`

export const WrapperButtonStyled = styled.button`
  width: 24px;
  height: 24px;
`
