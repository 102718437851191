import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerActiveBenefitsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  row-gap: 12px;
`

export const SkeletonSearchStyled = styled(Skeleton)`
  height: 48px;

  border-radius: ${({ theme }) => theme.borders.radius.pill};
`

export const SkeletonBenefitStyled = styled(Skeleton)`
  height: 40px;

  border-radius: ${({ theme }) => theme.borders.radius.s};
`

export const ButtonBenefit = styled.button`
  display: flex;

  width: 100%;
  height: 40px;

  padding: 8px 16px;

  border-radius: ${({ theme }) => theme.borders.radius.s};

  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};

  transition: all 0.2s ease-in;

  overflow: hidden;
  white-space: nowrap;

  p {
    font-weight: 600;

    color: ${({ theme }) => theme.colors.neutral[50]};

    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover,
  &.active {
    background-color: ${({ theme }) => theme.colors.secondary[95]};
    border-color: ${({ theme }) => theme.colors.primary};

    p {
      color: ${({ theme }) => theme.colors.secondary[50]};
    }
  }
`
