import { Table } from '@flash-tecnologia/hros-web-ui-v2'
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination'
import { useCallback } from 'react'

export type OnPaginationChange = {
  page: number
  pageSize: number
}

type PaginationProps = {
  count: number
  pageCurrent: number
  pageNumber: number
  pageSize: number
  onPaginationChange: ({ page, pageSize }: OnPaginationChange) => void
}

export function Pagination({
  count,
  pageCurrent,
  pageNumber,
  pageSize,
  onPaginationChange,
}: PaginationProps) {
  const handleChange = useCallback(
    (state: PaginationState) => {
      const pagination = { page: state.pageNumber, pageSize: state.pageSize }

      if (pageSize !== state.pageSize) {
        pagination.page = 1
      }

      onPaginationChange(pagination)
    },
    [onPaginationChange, pageSize],
  )

  return (
    <Table.Pagination
      count={count}
      onPaginationChange={handleChange}
      pagination={{
        index: pageCurrent,
        pageNumber,
        pageSize,
      }}
      pageSizeOptions={[
        { label: '10 itens', value: 10 },
        { label: '25 itens', value: 25 },
      ]}
      showItemRange
    />
  )
}
