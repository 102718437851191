import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
`

export const ContentWrapperStyled = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
`

export const NavHeaderContainerStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacings.xs1}
    ${({ theme }) => theme.spacings.xs};
  width: 100%;
`
export const HeaderBreadCrumbsTextStyled = styled(Typography)`
  font-weight: 700;
`

export const NavFooterContainerStyled = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  box-shadow: ${({ theme }) => theme.shadows[10]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.xs2}
    ${({ theme }) => theme.spacings.xs};
  width: 100%;
`

export const WrapperStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const ContentGridContainerStyled = styled.div`
  column-gap: ${({ theme }) => theme.spacings.xs};
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: max-content;
  height: 100%;
  overflow: scroll;
  padding: 0 ${({ theme }) => theme.spacings.xs}
    ${({ theme }) => theme.spacings.m};
  row-gap: ${({ theme }) => theme.spacings.xs2};
  width: 100%;
`
