import styled from 'styled-components'

import { RightPanelContainerStyled } from '../components/ContentTemplate/styled'

export const RightPanelStyled = styled(RightPanelContainerStyled)`
  display: flex;
  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.s};
`
