import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
`

export const WrapperTextsStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.xs2};
`

export const TextTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`

export const TextDescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const DropdownItemStyled = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.xs4};

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`
