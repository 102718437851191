import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useNavigate } from 'react-router-dom'
import { Space } from 'src/components/Space'
import { useTheme } from 'styled-components'

import {
  ImageStyled,
  StyledContainer,
  StyledDescriptionContainer,
  StyledLinkButton,
} from './styled'

export const FlashBenefitEmptyStateTab = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <StyledContainer>
      <Space y="l" />
      <Typography
        variant="headline6"
        variantColor={theme.colors.neutral[20]}
        weight={700}
      >
        Para atribuir Benefícios Flash, acesse a página de Pedido de Benefícios
      </Typography>
      <Space y="xs2" />
      <StyledDescriptionContainer>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[30]}
          weight={600}
        >
          Estamos trabalhando para unificar as experiências, no momento nesta
          seção é possível atribuir apenas Benefícios Externos.
        </Typography>
      </StyledDescriptionContainer>
      <Space y="xs2" />
      <StyledLinkButton
        variant="primary"
        onClick={() => navigate('/benefits/dashboard/company/employees')}
        aria-label="Ir para Pedido de Benefícios"
      >
        <span>Ir para Pedido de Benefícios </span>
        <Icons
          name="IconShoppingCart"
          color={theme.colors.secondary[50]}
          fill="none"
        />
      </StyledLinkButton>
      <Space y="xs" />
      <ImageStyled src="https://images.flashapp.com.br/Front-Assets/Images/SimpleAssignment/benefit_empty_state.png" />
      <Space y="l" />
    </StyledContainer>
  )
}
