import { ReactNode } from 'react'

import { ContainerStyled } from './styled'

type FocusedPageTemplateProps = {
  children: ReactNode
}

export const Root = ({ children }: FocusedPageTemplateProps) => {
  return <ContainerStyled>{children}</ContainerStyled>
}
