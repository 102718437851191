import { useEffect } from 'react'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import { ModalAlertConfirm } from '../../../../components/ModalAlertConfirm'

type ModalConfirmRemoveEmployeeProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export function ModalConfirmRemoveEmployee({
  isOpen,
  onClose,
  onConfirm,
}: ModalConfirmRemoveEmployeeProps) {
  const { trackEvent } = useEventTracking()

  const handleCancel = () => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_RemoveEmployee_Cancel_Click',
    })

    onClose()
  }

  const handleConfirm = () => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_RemoveEmployee_Confirm_Click',
    })

    onConfirm()
  }

  useEffect(() => {
    if (isOpen) {
      trackEvent({ name: 'FlashOS_SimpleAssignment_RemoveEmployee_View' })
    }
  }, [isOpen, trackEvent])

  return (
    <ModalAlertConfirm.Root
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={handleCancel}
    >
      <ModalAlertConfirm.Title>Retirar pessoa</ModalAlertConfirm.Title>

      <ModalAlertConfirm.Description>
        Você já definiu valores para esta pessoa no terceiro passo. Deseja
        retirá-la dessa atribuição mesmo assim?
      </ModalAlertConfirm.Description>
    </ModalAlertConfirm.Root>
  )
}
