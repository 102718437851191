import { BaseDataType } from '@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { trpc } from 'src/api/client'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import { useNewAssignmentStore } from '../useNewAssignmentStore'

export type EmployeeDataType = {
  name: string
  document: string
  documentFormatted: string
  hasBenefitAssignments: boolean
} & BaseDataType

type ListEmployeeType = 'available' | 'selected'

type EmployeeList = {
  employees: EmployeeDataType[]
  count: number
}

type EmployeeTransferList = {
  available: EmployeeList
  selected: EmployeeList
}

function filterByNameOrDocument(employees: EmployeeDataType[], search: string) {
  const lowerSearch = search.toLowerCase()

  if (!search) {
    return employees
  }

  return employees.filter((employee) => {
    return (
      employee.name.toLowerCase().includes(lowerSearch) ||
      employee.document.includes(lowerSearch) ||
      employee.documentFormatted.includes(lowerSearch)
    )
  })
}

export const useSelectEmployees = () => {
  const {
    chosenBenefits,
    chosenEmployees,
    handleOpenModalCancel,
    addChosenEmployees,
    assignmentAmounts,
    isShowButtonPrev,
    pageTitle,
  } = useNewAssignmentStore()

  const { trackPage, trackEvent } = useEventTracking()
  const navigate = useNavigate()

  const { data: employees = [], isLoading } =
    trpc.getEmployeesToNewAssignment.useQuery(
      {
        benefitIds: chosenBenefits.map((benefit) => benefit.id),
      },
      { refetchOnWindowFocus: false },
    )

  const [employeesList, setEmployeesList] = useState<EmployeeTransferList>({
    available: { count: 0, employees: [] },
    selected: { count: 0, employees: [] },
  })

  const [searchEmployee, setSearchEmployee] = useState({
    available: '',
    selected: '',
  })
  const [debouncedSearch, setDebouncedSearch] = useState({
    available: '',
    selected: '',
  })
  const [isShowModalRemoveEmployee, setIsShowModalRemoveEmployee] =
    useState(false)

  useDebounce(
    () => {
      setDebouncedSearch(searchEmployee)
    },
    500,
    [searchEmployee],
  )

  const onCheckItem = useCallback(
    (
      data: EmployeeDataType[],
      allChecked: boolean | undefined,
      list: ListEmployeeType,
    ) => {
      let employees = data

      if (allChecked !== undefined) {
        employees = data.map((employee) => ({
          ...employee,
          checked: allChecked,
        }))
      }

      setEmployeesList((currState) => ({
        ...currState,
        [list]: { employees, count: employees.length },
      }))
    },
    [],
  )

  const isRemovingAssignedEmployee = useCallback(
    (availableEmployees: EmployeeDataType[]): boolean => {
      const employeesWithAssignedValuesIds = assignmentAmounts.reduce(
        (acc, assignmentAmount) => {
          assignmentAmount.employees.forEach((employee) => {
            if (employee.value) {
              acc.add(employee.id)
            }
          })

          return acc
        },
        new Set(),
      )

      const hasEmployeesAssignedValue = availableEmployees.some((employee) =>
        employeesWithAssignedValuesIds.has(employee._id),
      )

      return hasEmployeesAssignedValue
    },
    [assignmentAmounts],
  )

  const onTransfer = useCallback(
    (
      availableEmployees: EmployeeDataType[],
      selectedEmployees: EmployeeDataType[],
    ) => {
      const isRemovingEmployee = isRemovingAssignedEmployee(availableEmployees)

      if (isRemovingEmployee) {
        return setIsShowModalRemoveEmployee(true)
      }

      const selectEmployees = selectedEmployees.map((employee) => ({
        id: employee._id,
        name: employee.name,
        document: employee.documentFormatted,
        image: '',
      }))

      addChosenEmployees(selectEmployees)
    },
    [addChosenEmployees, isRemovingAssignedEmployee],
  )

  const onSearch = useCallback(
    (term: string, list: ListEmployeeType) => {
      if (searchEmployee[list] !== term) {
        setSearchEmployee((searchEmployeeTerm) => ({
          ...searchEmployeeTerm,
          [list]: term,
        }))
      }
    },
    [searchEmployee],
  )

  const handleCloseModalRemoveEmployee = useCallback(() => {
    setIsShowModalRemoveEmployee(false)
  }, [])

  const handleConfirmModalRemoveEmployee = useCallback(() => {
    const notRemovedEmployees = employeesList.selected.employees.filter(
      (empl) => !empl.checked,
    )

    onTransfer([], notRemovedEmployees)

    handleCloseModalRemoveEmployee()
  }, [handleCloseModalRemoveEmployee, employeesList, onTransfer])

  const canNavigateToNextPage = useMemo(() => {
    return chosenEmployees.length > 0
  }, [chosenEmployees])

  const trackAndNavigateToNextPage = useCallback(() => {
    if (canNavigateToNextPage) {
      trackEvent({
        name: 'FlashOS_SimpleAssignment_NewAssignmentEmployees_Continue_Click',
      })
      navigate('/simple-assignment/new-assignment/amounts')
    }
  }, [trackEvent, navigate, canNavigateToNextPage])

  const trackAndNavigateToCancel = useCallback(() => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_NewAssignmentEmployees_Cancel_Click',
    })
    handleOpenModalCancel()
  }, [trackEvent, handleOpenModalCancel])

  const navigateToPreviousPage = useCallback(() => {
    navigate('/simple-assignment/new-assignment/')
  }, [navigate])

  useEffect(() => {
    const hasSelectedBenefits = chosenBenefits.length > 0

    if (!hasSelectedBenefits) {
      navigate('/simple-assignment/new-assignment/')
    }

    trackPage({ name: 'FlashOS_SimpleAssignment_NewAssignmentEmployees_View' })
  }, [trackPage, navigate, chosenBenefits.length])

  useEffect(() => {
    if (!isLoading) {
      setEmployeesList(() => {
        const available: EmployeeDataType[] = []
        const selected: EmployeeDataType[] = []

        employees.forEach((employee) => {
          const employeeData = {
            ...employee,
            _id: employee.id,
            checked: false,
            hidden: false,
          }

          if (chosenEmployees.find(({ id }) => id === employee.id)) {
            return selected.push(employeeData)
          }

          available.push(employeeData)
        })

        return {
          available: {
            employees: filterByNameOrDocument(
              available,
              debouncedSearch.available,
            ),
            count: available.length,
          },
          selected: {
            employees: filterByNameOrDocument(
              selected,
              debouncedSearch.selected,
            ),
            count: selected.length,
          },
        }
      })
    }
  }, [employees, isLoading, debouncedSearch, chosenEmployees])

  return {
    selectedEmployees: employeesList.selected.employees,
    selectedEmployeesTotalCount: employeesList.selected.count,
    availableEmployees: employeesList.available.employees,
    availableEmployeesTotalCount: employeesList.available.count,
    isLoading,
    pageTitle,
    onCheckItem,
    onSearch,
    onTransfer,
    trackAndNavigateToNextPage,
    trackAndNavigateToCancel,
    navigateToPreviousPage,
    canNavigateToNextPage,
    isShowModalRemoveEmployee,
    handleCloseModalRemoveEmployee,
    handleConfirmModalRemoveEmployee,
    isShowButtonPrev,
  }
}
