import { SearchField, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { clsx } from 'clsx'

import {
  ButtonBenefit,
  ContainerActiveBenefitsStyled,
  SkeletonBenefitStyled,
  SkeletonSearchStyled,
} from './styles'
import { useActiveBenefits } from './useActiveBenefits'

export function ActiveBenefits() {
  const {
    benefits,
    isLoading,
    benefitIdSelected,
    noBenefitsExist,
    handleClickedBenefit,
    handleSearchBenefit,
  } = useActiveBenefits()

  if (isLoading) {
    return (
      <ContainerActiveBenefitsStyled>
        <SkeletonSearchStyled />

        <SkeletonBenefitStyled />
        <SkeletonBenefitStyled />
        <SkeletonBenefitStyled />
      </ContainerActiveBenefitsStyled>
    )
  }

  return (
    <ContainerActiveBenefitsStyled>
      <SearchField
        label="Buscar por benefício"
        disabled={noBenefitsExist}
        onChange={handleSearchBenefit}
      />

      {benefits.map((benefit) => (
        <ButtonBenefit
          key={benefit.id}
          className={clsx({
            active: benefitIdSelected === benefit.id,
          })}
          onClick={() => handleClickedBenefit(benefit)}
        >
          <Typography variant="body4">{benefit.name}</Typography>
        </ButtonBenefit>
      ))}
    </ContainerActiveBenefitsStyled>
  )
}
