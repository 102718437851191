import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { ColumnsProp } from 'src/components/Table/Grid'
import { ChosenEmployee } from 'src/pages/NewAssignment/useNewAssignmentStore'

import { CellAmount } from './Cells/CellAmount'
import { CellName } from './Cells/CellName'
import { HeaderName } from './Headers/HeaderName'
import { HeaderValue } from './Headers/HeaderValue'

type UseConfigColumnsProps = {
  benefitId: string
  employees: ChosenEmployee[]
  selectedEmployees: string[]
  timeUnitLabel: string
  setSelectedEmployees: Dispatch<SetStateAction<string[]>>
}

export const useConfigColumns = ({
  benefitId,
  employees,
  selectedEmployees,
  timeUnitLabel,
  setSelectedEmployees,
}: UseConfigColumnsProps) => {
  const isAllChecked = selectedEmployees.length === employees.length

  const totalEmployees = employees.length

  const totalSelectedEmployees = selectedEmployees.length

  const isChecked = useCallback(
    (employeeId: string): boolean => {
      return selectedEmployees.includes(employeeId)
    },
    [selectedEmployees],
  )

  const toggleAllEmployees = useCallback(() => {
    const employeeIds = employees.map(({ id }) => id)

    setSelectedEmployees(isAllChecked ? [] : employeeIds)
  }, [employees, isAllChecked, setSelectedEmployees])

  const toggleCheckEmployee = useCallback(
    (employeeId: string) => {
      setSelectedEmployees((oldSelected) =>
        oldSelected.includes(employeeId)
          ? oldSelected.filter((selectedId) => selectedId !== employeeId)
          : [...oldSelected, employeeId],
      )
    },
    [setSelectedEmployees],
  )

  const columns: ColumnsProp<ChosenEmployee> = [
    {
      accessor: 'id',
      Header: () => (
        <Checkbox checked={isAllChecked} onChange={toggleAllEmployees} />
      ),
      Cell: ({ row }) => (
        <Checkbox
          checked={isChecked(row.original.id)}
          onChange={() => toggleCheckEmployee(row.original.id)}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: (
        <HeaderName
          selectedTotal={totalSelectedEmployees}
          total={totalEmployees}
        />
      ),
      accessor: 'name',
      Cell: ({ row }) => (
        <CellName
          cpf={row.original.document}
          image={row.original.image}
          name={row.original.name}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: (
        <HeaderValue
          benefitId={benefitId}
          selectedEmployees={selectedEmployees}
          timeUnitLabel={timeUnitLabel}
        />
      ),
      accessor: 'value',
      Cell: ({ row }) => (
        <CellAmount
          benefitId={benefitId}
          id={row.original.id}
          value={row.original.value}
          isInvalid={row.original.isInvalid}
        />
      ),
      disableSortBy: true,
    },
  ]

  useEffect(() => {
    setSelectedEmployees([])
  }, [employees, setSelectedEmployees])

  return {
    columns,
  }
}
