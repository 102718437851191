import { trpc } from 'src/api/client'

export function useIsSimpleAssignmentEnabled() {
  const { data, isLoading } = trpc.getCompany.useQuery(undefined, {
    staleTime: 30 * 60 * 1000, // 30 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: true,
  })

  return {
    isLoadingAssignmentEnabled: isLoading,
    isSimpleAssignmentEnabled: data?.enabled ?? false,
  }
}
