import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useCurrencyInput } from 'src/utils/hooks/useCurrencyInput'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import {
  ChosenEmployee,
  TypeValue,
  useNewAssignmentStore,
} from '../../useNewAssignmentStore'

const escapeRegExp = (value: string) =>
  value.replace(/[.*+?ˆ${}()|[\]\\]/g, '\\$&')
const removeSpecialCharacters = (text: string) => text.replace(/[.-]/g, '')

export const useBenefitAccordion = (
  benefitId: string,
  typeValue: TypeValue,
  employees: ChosenEmployee[],
) => {
  const { trackEvent } = useEventTracking()
  const {
    updateTypeValueBenefit,
    assignBenefitToAllEmployees,
    assignmentAmounts,
  } = useNewAssignmentStore()
  const { handleInputChange, setValueInCents, valueInCurrency, valueInCents } =
    useCurrencyInput()

  const [searchEmployee, setSearchTerm] = useState('')
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([])

  const filteredEmployees = useMemo(() => {
    if (!searchEmployee) {
      return employees
    }
    const searchRegex = new RegExp(searchEmployee, 'i')

    return employees.filter(
      (employee) =>
        searchRegex.test(employee.name) ||
        removeSpecialCharacters(employee.document).includes(searchEmployee),
    )
  }, [employees, searchEmployee])

  const clickedRadio = useCallback(
    (type: TypeValue) => {
      trackEvent({
        name:
          type === TypeValue.SET_INDIVIDUAL_AMOUNTS
            ? 'FlashOS_SimpleAssignment_NewAssignmentValues_DifferentValue_Click'
            : 'FlashOS_SimpleAssignment_NewAssignmentValues_SameValue_Click',
      })

      updateTypeValueBenefit(benefitId, type)
    },
    [benefitId, trackEvent, updateTypeValueBenefit],
  )

  const handleSearchTermChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value.trim()
      const lowerCaseSearchTerm = escapeRegExp(searchValue).toLowerCase()

      setSearchTerm(lowerCaseSearchTerm)
    },
    [],
  )

  const handleSearchTermFocus = useCallback(() => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_NewAssignmentValues_Search_Click',
    })
  }, [trackEvent])

  useEffect(() => {
    if (!valueInCurrency && typeValue === TypeValue.SET_SAME_AMOUNT_FOR_ALL) {
      const benefitAssignmentAmount = assignmentAmounts.find(
        (assignment) => assignment.benefit.id === benefitId,
      )

      const assignedValueForAllEmployees =
        benefitAssignmentAmount?.employees.find(
          (empl) => empl.value !== null,
        )?.value

      if (assignedValueForAllEmployees) {
        setValueInCents(assignedValueForAllEmployees * 100)
      }
    }
  }, [
    valueInCurrency,
    typeValue,
    assignmentAmounts,
    benefitId,
    setValueInCents,
  ])

  useEffect(() => {
    if (typeValue === TypeValue.SET_SAME_AMOUNT_FOR_ALL) {
      let value: number | null = null

      if (valueInCurrency) {
        value = valueInCents / 100

        assignBenefitToAllEmployees(benefitId, value)
      }
    } else {
      setValueInCents(0)
    }
  }, [
    assignBenefitToAllEmployees,
    benefitId,
    setValueInCents,
    typeValue,
    valueInCents,
    valueInCurrency,
  ])

  return {
    valueInCurrency,
    filteredEmployees,
    selectedEmployees,
    handleInputChange,
    handleSearchTermChange,
    handleSearchTermFocus,
    clickedRadio,
    setSelectedEmployees,
  }
}
