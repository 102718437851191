import { ToastProvider } from '@flash-tecnologia/hros-web-ui-v2'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { BenefitsNotFound } from 'src/pages/BenefitsNotFound'
import { NewAssignment } from 'src/pages/NewAssignment'
import { SelectBenefits } from 'src/pages/NewAssignment/SelectBenefits'
import { SelectEmployees } from 'src/pages/NewAssignment/SelectEmployees'
import { SetAmounts } from 'src/pages/NewAssignment/SetAmounts'
import { SimpleAssignment } from 'src/pages/SimpleAssignment'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Routes>
          <Route path="/simple-assignment" element={<SimpleAssignment />}>
            <Route
              path="/simple-assignment/benefits-not-found"
              element={<BenefitsNotFound />}
            />

            <Route path="/simple-assignment/flash-benefits" />
          </Route>

          <Route
            path="/simple-assignment/new-assignment"
            element={<NewAssignment />}
          >
            <Route index element={<SelectBenefits />} />

            <Route
              path="/simple-assignment/new-assignment/employees"
              element={<SelectEmployees />}
            />

            <Route
              path="/simple-assignment/new-assignment/amounts"
              element={<SetAmounts />}
            />
          </Route>
        </Routes>
      </ToastProvider>
    </BrowserRouter>
  )
}

export default AppRouter
