import {
  Icons,
  LinkButton,
  PageContainer,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import EmptyStateAsset from '../../assets/EmptyState.svg'
import { useEventTracking } from '../../utils/hooks/useEventTracking'
import {
  ContainerStyled,
  PageDescriptionStyled,
  PageTextContainer,
  PageTitleStyled,
} from './styled'

export function BenefitsNotFound() {
  const theme = useTheme()

  const navigate = useNavigate()

  const { trackEvent } = useEventTracking()

  const trackAndNavigateToBenefitManagement = () => {
    trackEvent({ name: 'FlashOS_SimpleAssignment_RedirectManagement_Click' })
    navigate('/benefit-management')
  }

  return (
    <>
      <PageContainer>
        <ContainerStyled>
          <EmptyStateAsset />

          <PageTextContainer>
            <PageTitleStyled variant="headline6">
              Nenhum benefício encontrado
            </PageTitleStyled>

            <PageDescriptionStyled variant="body3">
              Antes de atribuir, ative ou crie benefícios na página de
              Benefícios
            </PageDescriptionStyled>

            <LinkButton
              variant="primary"
              onClick={trackAndNavigateToBenefitManagement}
              aria-label="Abrir página de Benefícios da empresa"
            >
              <span>Benefícios da empresa </span>
              <Icons
                name="IconLayoutGridAdd"
                color={theme.colors.secondary[50]}
                fill="none"
              />
            </LinkButton>
          </PageTextContainer>
        </ContainerStyled>
      </PageContainer>
    </>
  )
}
