import { ReactNode } from 'react'

import { TextAttentionStyled, TitleStyled, WrapperTitleStyled } from './styles'

type TitleProps = {
  children: ReactNode
}

export function Title({ children }: TitleProps) {
  return (
    <WrapperTitleStyled>
      <TextAttentionStyled variant="headline9">Atenção!</TextAttentionStyled>
      <TitleStyled variant="headline6">{children}</TitleStyled>
    </WrapperTitleStyled>
  )
}
