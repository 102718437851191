import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import * as FocusedPageTemplate from 'src/components/FocusedPageTemplate'
import { useIsSimpleAssignmentEnabled } from 'src/utils/hooks/useIsSimpleAssignmentEnabled'

import { ModalCancel } from './ModalCancel'
import { useNewAssignmentStore } from './useNewAssignmentStore'

const breadcrumbRoutes = [
  { label: 'Atribuição de benefícios', route: '/simple-assignment' },
  { label: 'Nova atribuição' },
]

export function NewAssignment() {
  const { isSimpleAssignmentEnabled, isLoadingAssignmentEnabled } =
    useIsSimpleAssignmentEnabled()
  const navigate = useNavigate()
  const resetState = useNewAssignmentStore((state) => state.resetState)

  useEffect(() => {
    if (!isSimpleAssignmentEnabled) {
      navigate('/simple-assignment')
    }
  }, [isLoadingAssignmentEnabled, isSimpleAssignmentEnabled, navigate])

  useEffect(() => {
    return () => {
      resetState()
    }
  }, [resetState])

  if (isLoadingAssignmentEnabled) {
    return null
  }

  return (
    <FocusedPageTemplate.Root>
      <FocusedPageTemplate.NavHeader breadCrumbRoutes={breadcrumbRoutes} />
      <Outlet />

      <ModalCancel />
    </FocusedPageTemplate.Root>
  )
}
