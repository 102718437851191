import { ReactNode } from 'react'

import { RightPanelContainerStyled } from './styled'

type RightPanelProps = {
  children: ReactNode
}

export function RightPanel({ children }: RightPanelProps) {
  return <RightPanelContainerStyled>{children}</RightPanelContainerStyled>
}
