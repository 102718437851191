import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalAlertConfirm } from 'src/components/ModalAlertConfirm'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import { useNewAssignmentStore } from '../useNewAssignmentStore'

export function ModalCancel() {
  const { trackEvent } = useEventTracking()
  const navigate = useNavigate()
  const { handleCloseModalCancel, isShowModalCancel } = useNewAssignmentStore()

  const handleCancel = () => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_ConfirmCancelModal_Cancel_Click',
    })

    handleCloseModalCancel()
  }

  const handleConfirm = () => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_ConfirmCancelModal_Confirm_Click',
    })

    navigate('/simple-assignment')
  }

  useEffect(() => {
    if (isShowModalCancel) {
      trackEvent({ name: 'FlashOS_SimpleAssignment_ConfirmCancelModal_View' })
    }
  }, [isShowModalCancel, trackEvent])

  return (
    <ModalAlertConfirm.Root
      isOpen={isShowModalCancel}
      onClose={handleCancel}
      onConfirm={handleConfirm}
    >
      <ModalAlertConfirm.Title>Cancelar atribuição</ModalAlertConfirm.Title>
      <ModalAlertConfirm.Description>
        Tem certeza que quer cancelar a atribuição dos benefícios?
      </ModalAlertConfirm.Description>
    </ModalAlertConfirm.Root>
  )
}
