import {
  TextDescriptionStyled,
  TextTitleStyled,
  WrapperTextsStyled,
} from './styled'

type TextsProps = {
  title: string
  description?: string
}

export function Texts({ title, description }: TextsProps) {
  const renderDescription = () => {
    if (description) {
      return (
        <TextDescriptionStyled variant="body3">
          {description}
        </TextDescriptionStyled>
      )
    }

    return null
  }

  return (
    <WrapperTextsStyled>
      <TextTitleStyled variant="headline6">{title}</TextTitleStyled>

      {renderDescription()}
    </WrapperTextsStyled>
  )
}
