import { useCallback, useEffect } from 'react'
import { trpc } from 'src/api/client'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'
import { useToast } from 'src/utils/hooks/useToast'

import { useModalBenefitAssignments } from '../context'

export const useModalExcludeBeneficiary = () => {
  const { trackPage, trackEvent } = useEventTracking()
  const { toastSuccess, toastError } = useToast()

  const { assignmentId, onClose, onCancel } = useModalBenefitAssignments()

  const { getBenefitAssignments } = trpc.useContext()

  const deleteAssignmentByIdMutation = trpc.deleteAssignmentById.useMutation()

  const handleConfirm = useCallback(() => {
    trackEvent({ name: 'FlashOS_SimpleAssignment_DeleteAssign_Confirm_Click' })

    deleteAssignmentByIdMutation.mutate(
      {
        assignmentId,
      },
      {
        onSuccess() {
          onClose()

          getBenefitAssignments.invalidate()

          toastSuccess({ title: 'Pessoa excluída com sucesso!' })
        },
        onError() {
          toastError({
            title: 'Erro ao excluir a pessoa',
            description: 'Tente novamente mais tarde.',
          })
        },
      },
    )
  }, [
    assignmentId,
    getBenefitAssignments,
    onClose,
    toastError,
    toastSuccess,
    trackEvent,
    deleteAssignmentByIdMutation,
  ])

  useEffect(() => {
    trackPage({ name: 'FlashOS_SimpleAssignment_DeleteAssign_View' })
  }, [trackPage])

  return {
    isLoading: deleteAssignmentByIdMutation.isLoading,
    handleConfirm,
    onCancel,
  }
}
