import formatCurrency from 'src/utils/formatCurrency'
import { create } from 'zustand'

export enum TypeValue {
  SET_SAME_AMOUNT_FOR_ALL = 'SET_SAME_AMOUNT_FOR_ALL',
  SET_INDIVIDUAL_AMOUNTS = 'SET_INDIVIDUAL_AMOUNTS',
}

export type ChosenBenefit = {
  id: string
  emoji: string
  name: string
  description: string
  depositTimeUnit: 'month' | 'day'
  typeValue: TypeValue
  isInvalid?: boolean
}

export type ChosenEmployee = {
  id: string
  name: string
  image: string
  document: string
  value?: number | null
  isInvalid?: boolean
}

export type AssignmentAmount = {
  benefit: ChosenBenefit
  employees: ChosenEmployee[]
}

type GetFormattedAssignmentAmount = {
  benefitId: string
  employees: string[]
  value: number
}

type UseNewAssignmentStoreState = {
  chosenBenefits: ChosenBenefit[]
  chosenEmployees: ChosenEmployee[]
  assignmentAmounts: AssignmentAmount[]
  isShowModalCancel: boolean
  pageTitle: string
  isShowButtonPrev: boolean
}

type UpdateAssignmentProps = {
  benefitId: string
  employeeId: string
  value: number
}

type UseNewAssignmentStore = UseNewAssignmentStoreState & {
  addChosenBenefit: (chosenBenefit: Omit<ChosenBenefit, 'typeValue'>) => void
  setChosenBenefits: (
    chosenBenefits: Omit<ChosenBenefit, 'typeValue'>[],
  ) => void
  removeChosenBenefit: (benefitId: string) => void
  resetChosenBenefits: () => void
  addChosenEmployees: (chosenEmployees: ChosenEmployee[]) => void
  updateTypeValueBenefit: (benefitId: string, typeValue: TypeValue) => void
  updateAssignment: (newAssignment: UpdateAssignmentProps) => void
  assignBenefitToAllEmployees: (benefitId: string, value: number) => void
  handleOpenModalCancel: () => void
  handleCloseModalCancel: () => void
  validateAssignmentAmount: () => boolean
  getFormattedAssignmentAmount: () => GetFormattedAssignmentAmount[]
  changeIsShowButtonPrev: () => void
  accordionDescription: (benefitId: string) => string
  resetState: () => void
}

const initialState: UseNewAssignmentStoreState = {
  assignmentAmounts: [],
  chosenBenefits: [],
  chosenEmployees: [],
  isShowModalCancel: false,
  isShowButtonPrev: true,
  pageTitle: 'Nova atribuição',
}

const updateChosenBenefitsAndAssignments = (
  benefits: Omit<ChosenBenefit, 'typeValue'>[],
) => {
  const updatedChosenBenefits: ChosenBenefit[] = benefits.map((benefit) => ({
    ...benefit,
    typeValue: TypeValue.SET_SAME_AMOUNT_FOR_ALL,
  }))

  const updatedAssignmentAmounts: AssignmentAmount[] =
    updatedChosenBenefits.map((benefit) => ({
      benefit,
      employees: [],
    }))

  return {
    chosenBenefits: updatedChosenBenefits,
    assignmentAmounts: updatedAssignmentAmounts,
  }
}

const updateAssignmentAmountsForBenefits = (
  state: UseNewAssignmentStoreState,
  benefitId: string,
  updateFunc: (employee: ChosenEmployee) => ChosenEmployee,
) => {
  return state.assignmentAmounts.map((assignment) =>
    assignment.benefit.id === benefitId
      ? { ...assignment, employees: assignment.employees.map(updateFunc) }
      : assignment,
  )
}

const getEmployeeBenefitAssignedValue = (
  employeeId: string,
  benefitId: string,
  assignmentAmounts: AssignmentAmount[],
): number | null => {
  const benefitAssignment = assignmentAmounts.find(
    (assignment) => assignment.benefit.id === benefitId,
  )

  const employee = benefitAssignment?.employees.find(
    (employee) => employee.id === employeeId,
  )

  return employee?.value || null
}

const sortByInvalid = (
  item1: { isInvalid?: boolean },
  item2: { isInvalid?: boolean },
) => {
  return (item2.isInvalid === true ? 1 : 0) - (item1.isInvalid === true ? 1 : 0)
}

export const useNewAssignmentStore = create<UseNewAssignmentStore>(
  (set, get) => ({
    ...initialState,
    addChosenBenefit(newChosenBenefit) {
      set((state) => ({
        ...state,
        chosenEmployees: [],
        ...updateChosenBenefitsAndAssignments([
          ...state.chosenBenefits,
          newChosenBenefit,
        ]),
      }))
    },
    setChosenBenefits(benefits) {
      set((state) => {
        return {
          ...state,
          chosenEmployees: [],
          ...updateChosenBenefitsAndAssignments(benefits),
        }
      })
    },
    resetChosenBenefits() {
      set(initialState)
    },
    removeChosenBenefit(benefitId) {
      set((state) => ({
        ...state,
        chosenBenefits: state.chosenBenefits.filter(
          (chosenBenefit) => chosenBenefit.id !== benefitId,
        ),
        chosenEmployees: [],
        assignmentAmounts: state.assignmentAmounts.filter(
          (assignment) => assignment.benefit.id !== benefitId,
        ),
      }))
    },
    addChosenEmployees(newChosenEmployees) {
      set((state) => {
        const updatedAssignmentAmounts: AssignmentAmount[] =
          state.chosenBenefits.map((benefit) => ({
            benefit,
            employees: newChosenEmployees.map((employee) => {
              const employeeAssignedValue = getEmployeeBenefitAssignedValue(
                employee.id,
                benefit.id,
                state.assignmentAmounts,
              )

              return { ...employee, value: employeeAssignedValue }
            }),
          }))

        return {
          ...state,
          chosenEmployees: newChosenEmployees,
          assignmentAmounts: updatedAssignmentAmounts,
        }
      })
    },
    updateTypeValueBenefit(benefitId, typeValue) {
      set((state) => {
        const updatedChosenBenefits = state.chosenBenefits.map((benefit) => {
          return benefit.id === benefitId ? { ...benefit, typeValue } : benefit
        })

        const updatedAssignmentAmounts = state.assignmentAmounts.map(
          (assignment) => {
            if (assignment.benefit.id === benefitId) {
              const updatedEmployees = assignment.employees.map((employee) => ({
                ...employee,
                value: null,
              }))

              assignment.benefit.typeValue = typeValue

              return {
                ...assignment,
                employees: updatedEmployees,
              }
            }

            return assignment
          },
        )

        return {
          ...state,
          chosenBenefits: updatedChosenBenefits,
          assignmentAmounts: updatedAssignmentAmounts,
        }
      })
    },
    updateAssignment({ benefitId, employeeId, value }) {
      set((state) => {
        const updatedAssignmentAmounts = updateAssignmentAmountsForBenefits(
          state,
          benefitId,
          (employee) =>
            employee.id === employeeId ? { ...employee, value } : employee,
        )

        return {
          ...state,
          assignmentAmounts: updatedAssignmentAmounts,
        }
      })
    },
    assignBenefitToAllEmployees(benefitId, value) {
      set((state) => {
        const newAssignmentAmounts: AssignmentAmount[] =
          state.assignmentAmounts.map((assignment) => {
            if (assignment.benefit.id === benefitId) {
              return {
                ...assignment,
                employees: assignment.employees.map((employee) => ({
                  ...employee,
                  value,
                })),
              }
            }

            return assignment
          })

        return {
          ...state,
          assignmentAmounts: newAssignmentAmounts,
        }
      })
    },
    handleOpenModalCancel() {
      set((state) => ({ ...state, isShowModalCancel: true }))
    },
    handleCloseModalCancel() {
      set((state) => ({ ...state, isShowModalCancel: false }))
    },
    validateAssignmentAmount() {
      let isValid = true

      set((state) => {
        const updatedAssignmentAmounts: AssignmentAmount[] =
          state.assignmentAmounts.map((assignment) => {
            let isInvalid = false

            const updatedEmployees: ChosenEmployee[] = assignment.employees
              .map((employee) => {
                if (employee.value === null) {
                  isInvalid = true

                  return { ...employee, isInvalid: true }
                }

                return { ...employee, isInvalid: false }
              })
              .sort(sortByInvalid)

            const updatedBenefit: ChosenBenefit = {
              ...assignment.benefit,
              isInvalid,
            }

            return {
              benefit: updatedBenefit,
              employees: updatedEmployees,
            }
          })

        const sortedAssignmentAmounts = updatedAssignmentAmounts.sort(
          (item1, item2) => sortByInvalid(item1.benefit, item2.benefit),
        )

        if (sortedAssignmentAmounts.find(({ benefit }) => benefit.isInvalid)) {
          isValid = false
        }

        return {
          ...state,
          assignmentAmounts: sortedAssignmentAmounts,
        }
      })

      return isValid
    },
    getFormattedAssignmentAmount() {
      const { assignmentAmounts } = get()

      const formattedData: GetFormattedAssignmentAmount[] = []

      assignmentAmounts.forEach((assignment) => {
        const employeeGroups = assignment.employees.reduce(
          (groups, employee) => {
            const value = employee.value ?? -1

            groups[value] = groups[value] || []

            groups[value].push(employee.id)

            return groups
          },
          {} as Record<number, string[]>,
        )

        Object.entries(employeeGroups).forEach(([value, employeeIds]) => {
          formattedData.push({
            benefitId: assignment.benefit.id,
            employees: employeeIds,
            value: Number(value),
          })
        })
      })

      return formattedData
    },
    changeIsShowButtonPrev() {
      set((state) => ({
        ...state,
        isShowButtonPrev: !state.isShowButtonPrev,
        pageTitle: `Atribuir pessoas ao benefício ${state.chosenBenefits[0].name}`,
      }))
    },
    accordionDescription(benefitId: string) {
      const state = get()

      const benefit = state.chosenBenefits.find(({ id }) => id === benefitId)
      const assignment = state.assignmentAmounts.find(
        (assignmentAmount) => assignmentAmount.benefit.id === benefitId,
      )

      switch (benefit?.typeValue) {
        case TypeValue.SET_SAME_AMOUNT_FOR_ALL: {
          const hasValue =
            assignment &&
            assignment.employees.some((employee) => employee.value !== null)

          if (hasValue) {
            const value = assignment.employees.find(
              (employee) => employee.value !== null,
            )?.value

            const currencyValue = formatCurrency((value ?? 0) * 100)

            return `${currencyValue} para todos`
          }

          break
        }

        case TypeValue.SET_INDIVIDUAL_AMOUNTS: {
          const hasIndividualValues =
            assignment &&
            assignment.employees.some((employee) => employee.value !== null)

          if (hasIndividualValues) {
            return 'Valores definidos separadamente'
          }
        }
      }

      return 'Valores a definir'
    },
    resetState() {
      set({ ...initialState })
    },
  }),
)
