import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trpc } from 'src/api/client'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import {
  SelectedBenefit,
  useFlashBenefitTabStore,
} from '../../useFlashBenefitTabStore'

export const useActiveBenefits = () => {
  const { trackEvent } = useEventTracking()
  const {
    data: benefits = [],
    error,
    isError,
    isLoading,
    isSuccess,
  } = trpc.getActiveBenefits.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
    },
  )

  const { setSelectedBenefit, selectedBenefit } = useFlashBenefitTabStore()

  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState('')

  const filteredBenefits = useMemo(
    () =>
      benefits.filter((benefit) =>
        benefit.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [benefits, searchValue],
  )

  const handleSearchBenefit = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    },
    [],
  )

  const handleClickedBenefit = useCallback(
    (benefit: SelectedBenefit) => {
      trackEvent({ name: 'FlashOS_SimpleAssignment_ActiveBenefits_Click' })

      setSelectedBenefit(benefit)
    },
    [setSelectedBenefit, trackEvent],
  )

  useEffect(() => {
    if (isError && error.data?.code === 'NOT_FOUND') {
      navigate('/simple-assignment/benefits-not-found')
    }
  }, [error, isError, navigate])

  useEffect(() => {
    if (isSuccess && benefits.length) {
      const [firstBenefit] = benefits

      setSelectedBenefit(firstBenefit)
    }
  }, [benefits, isSuccess, setSelectedBenefit])

  return {
    benefits: filteredBenefits,
    noBenefitsExist: benefits.length === 0,
    isLoading,
    benefitIdSelected: selectedBenefit?.id,
    handleClickedBenefit,
    handleSearchBenefit,
  }
}
