import {
  Button as FlashButton,
  Icons,
  IconTypes,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

type ButtonProps = {
  iconName: IconTypes
  title: string
  onClick: () => void
  disabled?: boolean
}

export function Button({ title, iconName, onClick, disabled }: ButtonProps) {
  const theme = useTheme()

  return (
    <FlashButton
      size="large"
      variant="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {title} <Icons name={iconName} color={theme.colors.neutral[100]} />
    </FlashButton>
  )
}
