import { useCallback, useEffect, useState } from 'react'
import { useNewAssignmentStore } from 'src/pages/NewAssignment/useNewAssignmentStore'
import { useCurrencyInput } from 'src/utils/hooks/useCurrencyInput'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

type UseHeaderValueProps = {
  benefitId: string
  selectedEmployees: string[]
}

export const useHeaderValue = ({
  benefitId,
  selectedEmployees,
}: UseHeaderValueProps) => {
  const { trackEvent, trackPage } = useEventTracking()
  const { handleInputChange, valueInCents, valueInCurrency, resetValue } =
    useCurrencyInput()
  const { chosenBenefits, updateAssignment } = useNewAssignmentStore()

  const [modalState, setModalState] = useState({
    isOpen: false,
    benefitName: chosenBenefits.find(({ id }) => id === benefitId)?.name,
  })

  const handleOpenModal = useCallback(() => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_SelectedEmployeesSet_Click',
    })

    setModalState((prev) => ({ ...prev, isOpen: true }))
  }, [trackEvent])

  const handleCancel = useCallback(() => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_SelectedEmployeesSet_Cancel_Click',
    })

    setModalState((prev) => ({ ...prev, isOpen: false }))
  }, [trackEvent])

  const handleConfirm = useCallback(() => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_SelectedEmployeesSet_Confirm_Click',
    })

    selectedEmployees.forEach((employeeId) => {
      updateAssignment({ benefitId, employeeId, value: valueInCents / 100 })
    })

    setModalState((prev) => ({ ...prev, isOpen: false }))
  }, [benefitId, selectedEmployees, trackEvent, updateAssignment, valueInCents])

  useEffect(() => {
    if (modalState.isOpen) {
      trackPage({
        name: 'FlashOS_SimpleAssignment_SelectedEmployeesSet_View',
      })

      resetValue()
    }
  }, [modalState.isOpen, resetValue, trackPage])

  return {
    handleInputChange,
    handleOpenModal,
    handleCancel,
    handleConfirm,
    modalState,
    valueInCurrency,
  }
}
