import { TitleContainerStyled, TitleStyled } from './styled'

type TitleProps = {
  title: string
}

export function Title({ title }: TitleProps) {
  return (
    <TitleContainerStyled>
      <TitleStyled variant="headline6">{title}</TitleStyled>
    </TitleContainerStyled>
  )
}
