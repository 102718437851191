import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { trpc } from 'src/api/client'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'
import { useToast } from 'src/utils/hooks/useToast'

import { useNewAssignmentStore } from '../useNewAssignmentStore'

export const useSetAmounts = () => {
  const {
    handleOpenModalCancel,
    getFormattedAssignmentAmount,
    validateAssignmentAmount,
    chosenBenefits,
    chosenEmployees,
    assignmentAmounts,
    pageTitle,
  } = useNewAssignmentStore()
  const { trackEvent, trackPage } = useEventTracking()
  const { toastSuccess, toastError } = useToast()
  const upsertAssignmentsMutation = trpc.upsertAssignments.useMutation()
  const navigate = useNavigate()

  const showToastError = useCallback(() => {
    toastError({
      title: 'Erro ao atribuir os benefícios.',
      description: 'Verifique as informações e tente novamente.',
    })
  }, [toastError])

  const handleSave = useCallback(() => {
    const isValid = validateAssignmentAmount()

    if (isValid) {
      trackEvent({
        name: 'FlashOS_SimpleAssignment_NewAssignmentValues_Save_Click',
      })

      const assignment = getFormattedAssignmentAmount()

      upsertAssignmentsMutation.mutate(assignment, {
        onSuccess: () => {
          toastSuccess({ title: 'Benefícios atribuídos com sucesso!' })

          navigate('/simple-assignment')
        },
        onError: showToastError,
      })
    } else {
      const accordions = document.getElementsByClassName('MuiAccordion-root')

      accordions.item(0)?.scrollIntoView({ behavior: 'smooth' })

      showToastError()
    }
  }, [
    getFormattedAssignmentAmount,
    navigate,
    showToastError,
    toastSuccess,
    trackEvent,
    upsertAssignmentsMutation,
    validateAssignmentAmount,
  ])

  const trackAndNavigateToCancel = useCallback(() => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_NewAssignmentValues_Cancel_Click',
    })

    handleOpenModalCancel()
  }, [trackEvent, handleOpenModalCancel])

  const navigateToPreviousPage = useCallback(() => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_NewAssignmentValues_Back_Click',
    })

    navigate('/simple-assignment/new-assignment/employees')
  }, [navigate, trackEvent])

  useEffect(() => {
    const hasSelectedBenefits = chosenBenefits.length > 0
    const hasSelectedEmployees = chosenEmployees.length > 0

    trackPage({ name: 'FlashOS_SimpleAssignment_NewAssignmentValues_View' })

    if (!hasSelectedBenefits) {
      return navigate('/simple-assignment/new-assignment/')
    }

    if (!hasSelectedEmployees) {
      return navigate('/simple-assignment/new-assignment/employees')
    }
  }, [chosenBenefits.length, chosenEmployees.length, navigate, trackPage])

  return {
    trackAndNavigateToCancel,
    navigateToPreviousPage,
    handleSave,
    pageTitle,
    assignments: assignmentAmounts,
    isLoading: upsertAssignmentsMutation.isLoading,
  }
}
