import { ReactNode } from 'react'

import { DescriptionStyled } from './styles'

type DescriptionProps = {
  children: ReactNode
}

export function Description({ children }: DescriptionProps) {
  return <DescriptionStyled variant="body4">{children}</DescriptionStyled>
}
