import { useTheme } from '@mui/material'
import { useCallback, useEffect, useRef } from 'react'
import { useToggle } from 'react-use'
import { useNewAssignmentStore } from 'src/pages/NewAssignment/useNewAssignmentStore'
import { useCurrencyInput } from 'src/utils/hooks/useCurrencyInput'

type UseColumnAmountProps = {
  id: string
  benefitId: string
  value: number | null
  isInvalid: boolean
}

export const useCellAmount = ({
  id,
  benefitId,
  value,
  isInvalid = false,
}: UseColumnAmountProps) => {
  const { updateAssignment } = useNewAssignmentStore()
  const [isEditing, handleToogleEdit] = useToggle(false)

  const { handleInputChange, setValueInCents, valueInCurrency, valueInCents } =
    useCurrencyInput()

  const theme = useTheme()

  const containerRef = useRef<HTMLDivElement>(null)

  const handleClickedButton = useCallback(() => {
    if (isEditing && valueInCurrency) {
      updateAssignment({
        benefitId,
        employeeId: id,
        value: valueInCents / 100,
      })
    }

    handleToogleEdit()
  }, [
    isEditing,
    valueInCurrency,
    handleToogleEdit,
    updateAssignment,
    benefitId,
    id,
    valueInCents,
  ])

  useEffect(() => {
    if (value !== null) {
      setValueInCents(value * 100)
    }
  }, [setValueInCents, value])

  useEffect(() => {
    const parentTd = containerRef.current?.parentElement

    if (parentTd) {
      parentTd.style.backgroundColor = isInvalid ? theme.colors.error90 : ''
      parentTd.dataset.invalid = isInvalid ? 'true' : 'false'
      parentTd.id = `${benefitId}:${id}`
    }
  }, [benefitId, id, isInvalid, theme.colors.error90])

  return {
    handleInputChange,
    handleClickedButton,
    containerRef,
    valueInCurrency,
    isEditing,
  }
}
