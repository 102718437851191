import {
  LeftPanelContainerStyled,
  PanelSubtitleStyled,
  PanelTitleStyled,
} from './styled'

type LeftPanelProps = {
  title: string
  subtitle: string
}

export function LeftPanel({ title, subtitle }: LeftPanelProps) {
  return (
    <LeftPanelContainerStyled>
      <PanelTitleStyled variant="headline7">{title}</PanelTitleStyled>

      <PanelSubtitleStyled variant="body3">{subtitle}</PanelSubtitleStyled>
    </LeftPanelContainerStyled>
  )
}
