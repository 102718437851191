import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'

import {
  ContainerStyled,
  WrapperAmountStyled,
  WrapperButtonStyled,
  WrapperMessageStyled,
} from './styles'
import { useCellAmount } from './useCellAmount'

type CellAmountsProps = {
  id: string
  benefitId: string
  value?: number | null
  isInvalid?: boolean
}

const amountFormatted = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
  }).format(value)
}

export function CellAmount({
  benefitId,
  id,
  isInvalid = false,
  value = null,
}: CellAmountsProps) {
  const {
    containerRef,
    handleClickedButton,
    handleInputChange,
    isEditing,
    valueInCurrency,
  } = useCellAmount({ benefitId, id, isInvalid, value })

  return (
    <ContainerStyled ref={containerRef}>
      <WrapperAmountStyled>
        {isEditing ? (
          <input
            value={valueInCurrency}
            onChange={handleInputChange}
            onBlur={handleClickedButton}
            autoFocus
          />
        ) : (
          <>
            <Typography variant="body3">
              {value === null ? 'Saldo' : amountFormatted(value)}
            </Typography>

            {isInvalid && value === null && (
              <WrapperMessageStyled variant="caption">
                <Icons name="IconAlertCircle" size={16} /> Campo obrigatório
              </WrapperMessageStyled>
            )}
          </>
        )}
      </WrapperAmountStyled>

      <WrapperButtonStyled onClick={handleClickedButton}>
        {isEditing ? <Icons name="IconCheck" /> : <Icons name="IconPencil" />}
      </WrapperButtonStyled>
    </ContainerStyled>
  )
}
