import {
  Breadcrumbs,
  Icons,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { HeaderBreadCrumbsTextStyled, NavHeaderContainerStyled } from './styled'

interface BreadCrumbRoutes {
  route?: string
  label: string
}

interface HeaderProps {
  breadCrumbRoutes: BreadCrumbRoutes[]
}

export const NavHeader = ({ breadCrumbRoutes }: HeaderProps) => {
  const navigate = useNavigate()

  const breadCrumbs = useMemo(
    () =>
      breadCrumbRoutes.map(({ label, route }) => {
        if (!route) {
          return (
            <HeaderBreadCrumbsTextStyled key={label} variant="body4">
              {label}
            </HeaderBreadCrumbsTextStyled>
          )
        }

        return (
          <LinkButton
            key={label}
            onClick={() => navigate(route)}
            variant="secondary"
          >
            {label}
          </LinkButton>
        )
      }),
    [breadCrumbRoutes, navigate],
  )

  return (
    <NavHeaderContainerStyled>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={breadCrumbs}
      />
    </NavHeaderContainerStyled>
  )
}
