import {
  LinkButton,
  Modal,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import styled, { css } from 'styled-components'

type TitleStyledProps = {
  ellipsis?: boolean
}

export const ContainerStyled = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  row-gap: ${({ theme }) => theme.spacings.xs2};
`

export const IconWrapperStyled = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 80px;
  height: 80px;

  border-radius: ${({ theme }) => theme.borders.radius.circular};

  background-color: ${({ theme }) => theme.colors.secondary[95]};

  color: ${({ theme }) => theme.colors.primary};

  &::before {
    content: '';

    width: 64px;
    height: 64px;

    position: absolute;

    border: 4px solid ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borders.radius.circular};
  }
`

export const TitleStyled = styled(Typography)<TitleStyledProps>`
  color: ${({ theme }) => theme.colors.neutral[20]};

  max-width: 420px;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`

export const DescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const TextFieldStyled = styled(TextField)`
  width: 320px;
`

export const ButtonCancelStyled = styled(LinkButton)`
  align-self: center !important; // TODO needs to be adjusted in the future using DS
`
