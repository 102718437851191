import { createContext, useContext } from 'react'

export type ModalBenefitAssignmentsContextData = {
  assignmentId: string
  benefitName: string
  balanceInCents: number
  onClose: () => void
  onCancel: () => void
}

const defaultContext: ModalBenefitAssignmentsContextData = {
  assignmentId: '',
  balanceInCents: 0,
  benefitName: '',
  onClose: () => {},
  onCancel: () => {},
}

export const ModalBenefitAssignmentsContext =
  createContext<ModalBenefitAssignmentsContextData>(defaultContext)

export const useModalBenefitAssignments = () => {
  const context = useContext(ModalBenefitAssignmentsContext)

  if (!context) {
    throw new Error(
      'useModalBenefitAssignments must be used within a ModalBenefitAssignmentsContext.Provider',
    )
  }

  return context
}
