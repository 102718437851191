import {
  Button,
  Icons,
  Modal as HROSModal,
} from '@flash-tecnologia/hros-web-ui-v2'
import { ChangeEvent } from 'react'

import {
  ButtonCancelStyled,
  ContainerStyled,
  DescriptionStyled,
  IconWrapperStyled,
  TextFieldStyled,
  TitleStyled,
} from './styles'

type RootProps = {
  isOpen: boolean
  isLoading?: boolean
  isTitleEllipsis?: boolean
  title: string
  description: string
  inputLabel: string
  valueInCurrency: string
  onClose: () => void
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  onCancel: () => void
  handleConfirm: () => void
}

export function EmployeeBalanceModal({
  isOpen,
  isLoading = false,
  title,
  isTitleEllipsis = false,
  description,
  inputLabel,
  valueInCurrency,
  onClose,
  handleInputChange,
  onCancel,
  handleConfirm,
}: RootProps) {
  return (
    <HROSModal.Root open={isOpen} onClose={onClose}>
      <>
        <ContainerStyled>
          <IconWrapperStyled>
            <Icons name="IconCurrencyDollar" size={40} />
          </IconWrapperStyled>

          <TitleStyled variant="headline6" ellipsis={isTitleEllipsis}>
            {title}
          </TitleStyled>

          <DescriptionStyled variant="body3">{description}</DescriptionStyled>

          <TextFieldStyled
            label={inputLabel}
            value={valueInCurrency}
            onChange={handleInputChange}
            disabled={isLoading}
          />
        </ContainerStyled>

        <HROSModal.Footer>
          <ButtonCancelStyled variant="secondary" onClick={onCancel}>
            Cancelar
          </ButtonCancelStyled>

          <Button
            variant="primary"
            size="medium"
            onClick={handleConfirm}
            disabled={isLoading || valueInCurrency === ''}
          >
            {isLoading ? (
              <>Atualizando...</>
            ) : (
              <>
                Confirmar <Icons name="IconCheck" />
              </>
            )}
          </Button>
        </HROSModal.Footer>
      </>
    </HROSModal.Root>
  )
}
