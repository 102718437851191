import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.l} 0;
`

export const PageTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`

export const PageDescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const PageTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  margin-top: ${({ theme }) => theme.spacings.s};

  button {
    align-self: auto !important;
  }
`
