import styled from 'styled-components'

import { RightPanelContainerStyled } from '../components/ContentTemplate/styled'

export const TransferListContainerStyled = styled.div`
  align-self: stretch;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  height: 70vh;
  padding: ${({ theme }) => theme.spacings.s};
`
export const RightPanelStyled = styled(RightPanelContainerStyled)`
  grid-column-end: 12;
`
