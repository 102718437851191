import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const TableContainerStyled = styled.div`
  align-self: stretch;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.s};
`

export const TableTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`
