import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const EmployeeItemContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 10px;
`

export const EmployeeInfoContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  p:first-child {
    font-weight: 600;

    color: ${({ theme }) => theme.colors.neutral[40]};
  }

  p:last-child {
    display: inline-flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.neutral[60]};

    column-gap: ${({ theme }) => theme.spacings.xs5};
  }
`

export const WarningTextStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]} !important;
`
