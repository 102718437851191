import { ActiveBenefits } from './components/ActiveBenefits'
import { BenefitAssignments } from './components/BenefitAssignments'
import { BoxStyled, ContainerStyled } from './styles'

export function FlashBenefitTab() {
  return (
    <ContainerStyled>
      <BoxStyled>
        <ActiveBenefits />
      </BoxStyled>

      <BoxStyled>
        <BenefitAssignments />
      </BoxStyled>
    </ContainerStyled>
  )
}
