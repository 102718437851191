import { Radio } from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode } from 'react'

import { ContainerStyled, WrapperTextStyled } from './styles'

type RootProps = {
  children: ReactNode
  checked?: boolean
  onClick?: () => void
}

export function Root({ children, checked = false, onClick }: RootProps) {
  return (
    <ContainerStyled onClick={onClick} type="button">
      <Radio checked={checked} />

      <WrapperTextStyled>{children}</WrapperTextStyled>
    </ContainerStyled>
  )
}
