import {
  BenefitDescriptionStyled,
  BenefitEmojiContainerStyled,
  BenefitItemContainerStyled,
  BenefitNameStyled,
} from './styles'

type ColumnBenefitProps = {
  description: string
  emoji: string
  name: string
}

export function ColumnBenefit({
  name,
  emoji,
  description,
}: ColumnBenefitProps) {
  return (
    <BenefitItemContainerStyled>
      <BenefitEmojiContainerStyled>
        <img src={emoji} alt={name} />
      </BenefitEmojiContainerStyled>

      <div>
        <BenefitNameStyled variant="body3">{name}</BenefitNameStyled>

        <BenefitDescriptionStyled variant="body4">
          {description}
        </BenefitDescriptionStyled>
      </div>
    </BenefitItemContainerStyled>
  )
}
