import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const TitleContainerStyled = styled.div`
  padding: ${({ theme }) => theme.spacings.s} 0;
  grid-column-start: 1;
  grid-column-end: 12;
`

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`

export const LeftPanelContainerStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
`

export const PanelTitleStyled = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
  color: ${({ theme }) => theme.colors.secondary[50]};
`

export const PanelSubtitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const RightPanelContainerStyled = styled.div`
  display: grid;
  grid-column-start: 4;
  grid-column-end: 11;
`
