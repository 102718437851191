import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;

  column-gap: ${({ theme }) => theme.spacings.xs};

  padding-bottom: ${({ theme }) => theme.spacings.s};
`

export const BoxStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.s};
`
