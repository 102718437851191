import { EmployeeBalanceModal } from 'src/components/EmployeeBalanceModal'

import { useModalEditBalance } from './useModalEditBalance'

type ModalEditBalanceProps = {
  isOpen: boolean
  inputLabel?: string
}

export function ModalEditBalance({
  isOpen,
  inputLabel = '',
}: ModalEditBalanceProps) {
  const {
    benefitName,
    valueInCurrency,
    isLoading,
    handleInputChange,
    handleConfirm,
    onCancel,
  } = useModalEditBalance()

  return (
    <EmployeeBalanceModal
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onCancel}
      handleConfirm={handleConfirm}
      handleInputChange={handleInputChange}
      onCancel={onCancel}
      inputLabel={inputLabel}
      valueInCurrency={valueInCurrency}
      isTitleEllipsis
      title={`Editar saldo de ${benefitName}`}
      description="Insira o novo saldo"
    />
  )
}
