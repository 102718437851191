import { create } from 'zustand'

export type SelectedBenefit = {
  id: string
  name: string
  emoji: string
  description: string
  depositTimeUnit: 'day' | 'month'
}

type UseFlashBenefitTabStore = {
  selectedBenefit: SelectedBenefit | null
  setSelectedBenefit: (benefit: SelectedBenefit) => void
  clearSelectedBenefit: () => void
}

export const useFlashBenefitTabStore = create<UseFlashBenefitTabStore>(
  (set) => ({
    selectedBenefit: null,
    setSelectedBenefit(benefit) {
      set({ selectedBenefit: benefit })
    },
    clearSelectedBenefit() {
      set({ selectedBenefit: null })
    },
  }),
)
