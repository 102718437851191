import { useCallback } from 'react'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import {
  ModalBenefitAssignmentsContext,
  ModalBenefitAssignmentsContextData,
} from './context'
import { ModalEditBalance } from './ModalEditBalance'
import { ModalExcludeBeneficiary } from './ModalExcludeBeneficiary'

export enum ModalBenefitAssignmentsType {
  EDIT_BALANCE = 'edit_balance',
  EXCLUDE_BENEFICIARY = 'exclude_beneficiary',
}

type ModalProps = Omit<
  ModalBenefitAssignmentsContextData,
  'balanceInCents' | 'onCancel'
> & {
  type: ModalBenefitAssignmentsType
  isOpen: boolean
  inputLabel?: string
  balance?: number
}

const COMPONENT_MAP = {
  [ModalBenefitAssignmentsType.EDIT_BALANCE]: ModalEditBalance,
  [ModalBenefitAssignmentsType.EXCLUDE_BENEFICIARY]: ModalExcludeBeneficiary,
}

export function ModalBenefitAssignments({
  isOpen,
  balance,
  benefitName,
  assignmentId,
  onClose,
  type,
  inputLabel,
}: ModalProps) {
  const { trackEvent } = useEventTracking()

  const balanceInCents = (balance ?? 0) * 100

  const ContentComponent = COMPONENT_MAP[type]

  const handleCancel = useCallback(() => {
    if (type === ModalBenefitAssignmentsType.EDIT_BALANCE) {
      trackEvent({ name: 'FlashOS_SimpleAssignment_EditAssign_Cancel_Click' })
    } else {
      trackEvent({ name: 'FlashOS_SimpleAssignment_DeleteAssign_Cancel_Click' })
    }

    onClose()
  }, [onClose, trackEvent, type])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <ModalBenefitAssignmentsContext.Provider
      value={{
        assignmentId,
        balanceInCents,
        benefitName,
        onClose: handleClose,
        onCancel: handleCancel,
      }}
    >
      <ContentComponent isOpen={isOpen} inputLabel={inputLabel} />
    </ModalBenefitAssignmentsContext.Provider>
  )
}
