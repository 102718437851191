import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ImageStyled = styled.img`
  object-fit: scale-down;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 570px;
  margin: auto;
`

export const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 400px;
  margin: auto;
`

export const StyledLinkButton = styled(LinkButton)`
  align-self: center !important;
`
