import { LinkButton, Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  row-gap: ${({ theme }) => theme.spacings.xs2};
`

export const IconWrapperStyled = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;

  border-radius: ${({ theme }) => theme.borders.radius.circular};

  background-color: #ffecd6;

  color: #c96c01;
`

export const WrapperTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  row-gap: ${({ theme }) => theme.spacings.xs5};
`

export const TextAttentionStyled = styled(Typography)`
  color: #c96c01;
`

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`

export const DescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  text-align: center;
`

export const ButtonCancelStyled = styled(LinkButton)`
  align-self: center !important; // TODO needs to be adjusted in the future using DS
`
