import {
  Accordion as HROSAccordion,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2'
import SimpleBar from 'simplebar-react'
import styled, { css } from 'styled-components'

type AccordionStyledProps = {
  isInvalid: boolean
}

export const AccordionStyled = styled(HROSAccordion)<AccordionStyledProps>`
  height: max-content;

  ${({ isInvalid, theme }) =>
    isInvalid &&
    css`
      border-color: ${theme.colors.error50};
    `};
`

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacings.xs};
`

export const WrapperContentAmountForAll = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacings.xs4};

  > p {
    font-weight: 600;
  }
`

export const TextFieldStyled = styled(TextField)`
  width: 320px;
`

export const WrapperSearchStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.xs4};

  > p {
    color: ${({ theme }) => theme.colors.neutral30};

    font-weight: 600;
  }
`

export const WrapperTableStyled = styled(SimpleBar)`
  overflow-y: auto;

  max-height: 528px;

  .simplebar-scrollbar::before {
    width: 8px;

    min-height: 30px;

    background-color: ${({ theme }) => theme.colors.neutral80};

    border-radius: ${({ theme }) => theme.borders.radius.pill};

    border: transparent;

    left: 0;
    right: 0;
  }

  .simplebar-track.simplebar-vertical {
    background-color: ${({ theme }) => theme.colors.neutral90};

    border-radius: ${({ theme }) => theme.borders.radius.pill};

    width: 8px;

    margin-right: 8px;
  }
`
