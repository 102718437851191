import {
  Button,
  Checkbox,
  Icons,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2'
import * as FocusedPageTemplate from 'src/components/FocusedPageTemplate'
import * as Table from 'src/components/Table'

import * as ContentTemplate from '../components/ContentTemplate'
import { ColumnBenefit } from './Columns/ColumnBenefit'
import { TableContainerStyled, TableTitleStyled } from './styled'
import { useSelectBenefits } from './useSelectBenefits'

export function SelectBenefits() {
  const {
    isLoading,
    searchBenefitByName,
    pageSize,
    searchedBenefits,
    toggleAllBenefits,
    isAllChecked,
    isChecked,
    toggleCheckBenefit,
    trackAndNavigateToCancel,
    trackAndNavigateToNextPage,
    canNavigateToNextPage,
  } = useSelectBenefits()

  return (
    <>
      <FocusedPageTemplate.Content>
        <ContentTemplate.Title title="Nova atribuição" />

        <ContentTemplate.LeftPanel
          title="Escolher benefícios"
          subtitle="Escolha os benefícios que serão atribuídos"
        />

        <ContentTemplate.RightPanel>
          <TableContainerStyled>
            <TableTitleStyled variant="headline8">Benefícios</TableTitleStyled>

            <Table.Root isLoading={isLoading}>
              <Table.Search
                label="Buscar por benefício"
                onChange={(e) => searchBenefitByName(e.target.value)}
              />

              <Table.Grid
                data={searchedBenefits}
                rowIdKey="id"
                columns={[
                  {
                    Header: () => (
                      <Checkbox
                        checked={isAllChecked}
                        onChange={toggleAllBenefits}
                      />
                    ),
                    accessor: 'id',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Cell: ({ row }: any) => (
                      <Checkbox
                        checked={isChecked(row.original.id)}
                        onChange={() => toggleCheckBenefit(row.original)}
                      />
                    ),
                    disableSortBy: true,
                  },
                  {
                    accessor: 'name',
                    Header: 'Benefício',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Cell: ({ row }: any) => (
                      <ColumnBenefit
                        name={row.original.name}
                        emoji={row.original.emoji}
                        description={row.original.description}
                      />
                    ),
                    disableSortBy: true,
                  },
                ]}
                pageCount={pageSize}
                pageSize={pageSize}
                emptyStateMessage=""
              />
            </Table.Root>
          </TableContainerStyled>
        </ContentTemplate.RightPanel>
      </FocusedPageTemplate.Content>

      <FocusedPageTemplate.NavFooter
        leftPositionElements={[
          <LinkButton
            variant="secondary"
            onClick={trackAndNavigateToCancel}
            key="cancel-button"
          >
            Cancelar
          </LinkButton>,
        ]}
        rightPositionElements={[
          <Button
            size="large"
            variant="primary"
            onClick={trackAndNavigateToNextPage}
            key="continue-button"
            disabled={!canNavigateToNextPage}
          >
            Próximo <Icons name="IconArrowRight" />
          </Button>,
        ]}
      />
    </>
  )
}
