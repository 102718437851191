import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2'

import * as Header from '../../components/Header'
import * as Tab from '../../components/Tab'
import { useSimpleAssignment } from './useSimpleAssignment'
import { AssignFlashBenefitNotificationCard } from './warnings/AssignFlashBenefitNotificationCard'

export function SimpleAssignment() {
  const {
    isLoadingAssignmentEnabled,
    canShowFlashBenefitsTab,
    tabParams,
    getTabsDropdown,
    navigateToNewAssignment,
  } = useSimpleAssignment()

  return (
    <>
      <Header.Root>
        <Header.Texts
          title="Atribuição de benefícios"
          description="Gerencie como os benefícios serão disponibilizados aos seus colaboradores"
        />
        <Header.Button
          title="Nova atribuição"
          iconName="Add"
          onClick={navigateToNewAssignment}
          disabled={isLoadingAssignmentEnabled}
        />
      </Header.Root>

      {!isLoadingAssignmentEnabled && (
        <PageContainer>
          {!canShowFlashBenefitsTab && <AssignFlashBenefitNotificationCard />}

          <Tab.Root
            tabs={getTabsDropdown.tabItems}
            selectedTab={tabParams.currentTab}
            onTabChanged={tabParams.setParams}
          />
        </PageContainer>
      )}
    </>
  )
}
