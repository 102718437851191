import { Button, IconButton, Icons } from '@flash-tecnologia/hros-web-ui-v2'
import * as Table from 'src/components/Table'

import { Columns } from './Columns'
import { ModalBenefitAssignments } from './ModalBenefitAssignments'
import {
  BoxFilterStyled,
  ContainerSkeletonStyled,
  SkeletonStyled,
} from './styles'
import { useBenefitAssignments } from './useBenefitAssignments'

function LoadingSkeleton() {
  return (
    <ContainerSkeletonStyled>
      <SkeletonStyled width="50%" height="28px" />

      <SkeletonStyled height="58px" />

      <SkeletonStyled height="40px" />

      <SkeletonStyled height="402px" />

      <SkeletonStyled height="58px" />
    </ContainerSkeletonStyled>
  )
}

export function BenefitAssignments() {
  const {
    simpleAssignments,
    benefitName,
    isLoading,
    pagination,
    searchTerm,
    infoModal,
    handleSearchChange,
    navigateToNewAssignment,
    setPagination,
    openModalEditBalance,
    openModalExcludeBeneficiary,
    closeModal,
  } = useBenefitAssignments()

  if (!benefitName) {
    return <LoadingSkeleton />
  }

  return (
    <>
      <Table.Root isLoading={isLoading}>
        <Table.Header title={`Pessoas atribuídas a ${benefitName}`} />

        <Table.Search
          label="Buscar por pessoas"
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <BoxFilterStyled>
          <IconButton
            size="small"
            variant="filled"
            onClick={navigateToNewAssignment}
          >
            <Icons name="IconUserPlus" />
          </IconButton>

          <IconButton size="small" variant="filled" disabled>
            <Icons name="IconDownload" />
          </IconButton>
        </BoxFilterStyled>

        <Table.Grid
          data={simpleAssignments.items}
          rowIdKey="id"
          columns={Columns}
          pageCount={simpleAssignments.count}
          pageSize={pagination.pageSize}
          actions={[
            {
              key: 'edit',
              icon: 'IconEdit',
              onClick: (assignmentId) => openModalEditBalance(assignmentId),
              label: 'Editar saldo',
            },
            {
              key: 'delete',
              icon: 'IconTrash',
              onClick: (assignmentId) =>
                openModalExcludeBeneficiary(assignmentId),
              label: 'Excluir deste benefício',
            },
          ]}
          emptyStateMessage="Você ainda não atribuiu pessoas a este benefício"
          emptyStateChildren={
            <Button
              size="large"
              variant="primary"
              onClick={navigateToNewAssignment}
            >
              Atribuir pessoas a este benefício <Icons name="IconUserPlus" />
            </Button>
          }
        />

        <Table.Pagination
          count={simpleAssignments.count}
          onPaginationChange={setPagination}
          pageCurrent={2}
          pageNumber={pagination.page}
          pageSize={pagination.pageSize}
        />
      </Table.Root>

      <ModalBenefitAssignments
        isOpen={infoModal.isOpenModal}
        onClose={closeModal}
        type={infoModal.typeModal}
        assignmentId={infoModal.assignmentId}
        balance={infoModal.value}
        benefitName={benefitName}
        inputLabel={infoModal.timeUnitLabel}
      />
    </>
  )
}
