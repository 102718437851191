import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.feedback.info[40]} !important;
  ::before {
    border-color: ${({ theme }) => theme.colors.feedback.info[40]} !important;
  }
`
