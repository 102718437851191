import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const BenefitItemContainerStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`

export const BenefitEmojiContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  padding: ${({ theme }) => theme.spacings.xs2};

  img {
    width: ${({ theme }) => theme.spacings.xs};
    height: ${({ theme }) => theme.spacings.xs};
  }
`

export const BenefitNameStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600;
`

export const BenefitDescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`
