import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trpc } from 'src/api/client'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import { useNewAssignmentStore } from '../useNewAssignmentStore'

export type Benefit = {
  description: string
  emoji: string
  id: string
  name: string
  depositTimeUnit: 'month' | 'day'
}

export const useSelectBenefits = () => {
  const { trackPage, trackEvent } = useEventTracking()
  const {
    chosenBenefits,
    addChosenBenefit,
    removeChosenBenefit,
    setChosenBenefits,
    handleOpenModalCancel,
    resetChosenBenefits,
  } = useNewAssignmentStore()

  const navigate = useNavigate()

  const { data: benefits, isLoading } = trpc.getActiveBenefits.useQuery({})

  const [searchedBenefits, setSearchedBenefits] = useState<Benefit[]>([])

  const selectedBenefits = chosenBenefits

  const isChecked = useCallback(
    (benefitId: string): boolean => {
      return !!selectedBenefits.find((benefit) => benefit.id === benefitId)
    },
    [selectedBenefits],
  )

  const isAllChecked = useMemo(
    () => selectedBenefits.length === benefits?.length,
    [benefits?.length, selectedBenefits.length],
  )

  const addSelectedBenefit = useCallback(
    (benefit: Benefit) => {
      addChosenBenefit(benefit)
    },
    [addChosenBenefit],
  )

  const removeSelectBenefit = useCallback(
    (benefit: Benefit) => {
      removeChosenBenefit(benefit.id)
    },
    [removeChosenBenefit],
  )

  const toggleCheckBenefit = useCallback(
    (benefit: Benefit) => {
      if (isChecked(benefit.id)) {
        return removeSelectBenefit(benefit)
      }

      return addSelectedBenefit(benefit)
    },
    [addSelectedBenefit, isChecked, removeSelectBenefit],
  )

  const toggleAllBenefits = useCallback(() => {
    if (isAllChecked) {
      return resetChosenBenefits()
    }

    return setChosenBenefits(benefits ?? [])
  }, [benefits, isAllChecked, resetChosenBenefits, setChosenBenefits])

  const searchBenefitByName = useCallback(
    (name: string) => {
      const lowerCasedName = name.toLocaleLowerCase()

      const searchedBenefits =
        benefits?.filter((benefit) =>
          benefit.name.toLowerCase().includes(lowerCasedName),
        ) || []

      setSearchedBenefits(searchedBenefits)
    },
    [benefits],
  )

  const canNavigateToNextPage = useMemo(() => {
    return chosenBenefits.length > 0
  }, [chosenBenefits])

  const trackAndNavigateToNextPage = useCallback(() => {
    if (canNavigateToNextPage) {
      trackEvent({
        name: 'FlashOS_SimpleAssignment_NewAssignmentBenefits_Continue_Click',
      })
      navigate('/simple-assignment/new-assignment/employees')
    }
  }, [navigate, trackEvent, canNavigateToNextPage])

  const trackAndNavigateToCancel = useCallback(() => {
    trackEvent({
      name: 'FlashOS_SimpleAssignment_NewAssignmentBenefits_Cancel_Click',
    })

    handleOpenModalCancel()
  }, [handleOpenModalCancel, trackEvent])

  useEffect(() => {
    trackPage({ name: 'FlashOS_SimpleAssignment_NewAssignmentBenefits_View' })
  }, [trackPage])

  useEffect(() => {
    setSearchedBenefits(benefits || [])
  }, [benefits])

  return {
    isLoading,
    searchBenefitByName,
    pageSize: benefits?.length ?? 0,
    searchedBenefits,
    toggleAllBenefits,
    isAllChecked,
    isChecked,
    toggleCheckBenefit,
    trackAndNavigateToCancel,
    trackAndNavigateToNextPage,
    handleOpenModalCancel,
    canNavigateToNextPage,
  }
}
