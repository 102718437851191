import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import { EmployeeBalanceModal } from 'src/components/EmployeeBalanceModal'

import { WrapperButtonStyled } from './styles'
import { useHeaderValue } from './useHeaderValue'

type HeaderValueProps = {
  benefitId: string
  selectedEmployees: string[]
  timeUnitLabel: string
}

export function HeaderValue({
  benefitId,
  selectedEmployees,
  timeUnitLabel,
}: HeaderValueProps) {
  const {
    handleCancel,
    handleConfirm,
    handleInputChange,
    handleOpenModal,
    modalState,
    valueInCurrency,
  } = useHeaderValue({ benefitId, selectedEmployees })

  const showBalanceButton = selectedEmployees.length > 1

  return (
    <>
      {showBalanceButton ? (
        <WrapperButtonStyled>
          <LinkButton variant="secondary" onClick={handleOpenModal}>
            <Icons name="IconPencil" /> Definir saldo para todos os selecionados
          </LinkButton>
        </WrapperButtonStyled>
      ) : (
        `${timeUnitLabel} (R$)`
      )}

      <EmployeeBalanceModal
        isOpen={modalState.isOpen}
        onClose={handleCancel}
        handleConfirm={handleConfirm}
        handleInputChange={handleInputChange}
        onCancel={handleCancel}
        inputLabel={timeUnitLabel}
        valueInCurrency={valueInCurrency}
        title={`Definir saldo de ${modalState.benefitName} para as pessoas`}
        description="Insira o saldo que deseja que as pessoas selecionadas recebam"
      />
    </>
  )
}
