import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.button`
  display: flex;

  gap: ${({ theme }) => theme.spacings.xs1};
  align-self: stretch;

  padding: 16px 24px;

  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral80};

  cursor: pointer;
  text-align: left;
  align-items: center;
`
export const WrapperTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  row-gap: 2px;

  max-width: 444px;
`

export const TitleStyled = styled(Typography)`
  font-weight: 600 !important;

  color: ${({ theme }) => theme.colors.neutral30};
`

export const DescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral50};
`
