import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import * as FocusedPageTemplate from 'src/components/FocusedPageTemplate'

import * as ContentTemplate from '../components/ContentTemplate'
import { BenefitAccordion } from './BenefitAccordion'
import { RightPanelStyled } from './styles'
import { useSetAmounts } from './useSetAmounts'

export function SetAmounts() {
  const {
    assignments,
    trackAndNavigateToCancel,
    navigateToPreviousPage,
    handleSave,
    isLoading,
    pageTitle,
  } = useSetAmounts()

  return (
    <>
      <FocusedPageTemplate.Content>
        <ContentTemplate.Title title={pageTitle} />

        <ContentTemplate.LeftPanel
          title="Definir valores"
          subtitle="Defina o saldo que os colaboradores receberão no(s) benefício(s)"
        />

        <RightPanelStyled>
          {assignments.map((assignment, index) => (
            <BenefitAccordion
              key={assignment.benefit.id}
              benefit={assignment.benefit}
              employees={assignment.employees}
              defaultExpanded={index === 0}
            />
          ))}
        </RightPanelStyled>
      </FocusedPageTemplate.Content>

      <FocusedPageTemplate.NavFooter
        leftPositionElements={[
          <LinkButton
            variant="secondary"
            onClick={trackAndNavigateToCancel}
            key="cancel-button"
          >
            Cancelar
          </LinkButton>,
        ]}
        rightPositionElements={[
          <Button
            size="large"
            variant="secondary"
            onClick={navigateToPreviousPage}
            key="back-button"
          >
            <Icons name="IconArrowLeft" /> Voltar
          </Button>,
          <Button
            size="large"
            variant="primary"
            onClick={handleSave}
            disabled={isLoading}
            key="continue-button"
          >
            {isLoading ? (
              'Aguarde...'
            ) : (
              <>
                Salvar <Icons name="IconCheck" />
              </>
            )}
          </Button>,
        ]}
      />
    </>
  )
}
