import {
  Button,
  Icons,
  Modal as HROSModal,
} from '@flash-tecnologia/hros-web-ui-v2'

import {
  ButtonCancelStyled,
  ContainerStyled,
  DescriptionStyled,
  TitleStyled,
} from '../styles'
import { IconWrapperStyled, TextAlertStyled } from './styles'
import { useModalExcludeBeneficiary } from './useModalExcludeBeneficiary'

type ModalExcludeBeneficiaryProps = {
  isOpen: boolean
}

export function ModalExcludeBeneficiary({
  isOpen,
}: ModalExcludeBeneficiaryProps) {
  const { isLoading, handleConfirm, onCancel } = useModalExcludeBeneficiary()

  return (
    <HROSModal.Root open={isOpen} onClose={onCancel}>
      <>
        <ContainerStyled>
          <IconWrapperStyled>
            <Icons name="IconAlertCircle" size={64} />
          </IconWrapperStyled>

          <div>
            <TextAlertStyled variant="body3">Atenção!</TextAlertStyled>
            <TitleStyled variant="headline6">
              Tem certeza que deseja excluir a pessoa deste benefício?
            </TitleStyled>
          </div>

          <DescriptionStyled variant="body3">
            Se confirmar a exclusão, a pessoa não estará mais atribuída ao
            benefício.
          </DescriptionStyled>
        </ContainerStyled>

        <HROSModal.Footer>
          <ButtonCancelStyled variant="secondary" onClick={onCancel}>
            Cancelar
          </ButtonCancelStyled>

          <Button
            variant="secondary-error"
            size="medium"
            onClick={handleConfirm}
            disabled={isLoading}
          >
            {isLoading ? (
              <>Excluindo...</>
            ) : (
              <>
                Confirmar exclusão <Icons name="IconTrash" />
              </>
            )}
          </Button>
        </HROSModal.Footer>
      </>
    </HROSModal.Root>
  )
}
