import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { trpc } from 'src/api/client'
import { useNewAssignmentStore } from 'src/pages/NewAssignment/useNewAssignmentStore'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import { useFlashBenefitTabStore } from '../../useFlashBenefitTabStore'
import { Assignment } from './Columns'
import { ModalBenefitAssignmentsType } from './ModalBenefitAssignments'

type PaginationState = {
  page: number
  pageSize: number
}

type InfoModalState = {
  isOpenModal: boolean
  typeModal: ModalBenefitAssignmentsType
  assignmentId: string
  timeUnitLabel?: string
  value?: number
}

export const useBenefitAssignments = () => {
  const { trackEvent } = useEventTracking()
  const { selectedBenefit } = useFlashBenefitTabStore()
  const { addChosenBenefit, changeIsShowButtonPrev } = useNewAssignmentStore()
  const navigate = useNavigate()

  const [pagination, setPagination] = useState<PaginationState>({
    page: 1,
    pageSize: 10,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [infoModal, setInfoModal] = useState<InfoModalState>({
    isOpenModal: false,
    typeModal: ModalBenefitAssignmentsType.EDIT_BALANCE,
    assignmentId: '',
    timeUnitLabel: '',
    value: 0,
  })

  const simpleAssignmentsItems = useRef<Assignment[]>([])

  const resetPagination = useCallback(() => {
    setPagination((prev) => ({ ...prev, page: 1 }))
  }, [])

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value

      if (value !== searchTerm) {
        setSearchTerm(value)
      }
    },
    [searchTerm],
  )

  const navigateToNewAssignment = useCallback(() => {
    trackEvent({ name: 'FlashOS_SimpleAssignment_FirstAssignment_Click' })

    if (selectedBenefit) {
      addChosenBenefit({
        id: selectedBenefit.id,
        name: selectedBenefit.name,
        depositTimeUnit: selectedBenefit.depositTimeUnit,
        description: selectedBenefit.description,
        emoji: selectedBenefit.emoji,
      })

      changeIsShowButtonPrev()

      navigate('/simple-assignment/new-assignment/employees')
    }
  }, [
    addChosenBenefit,
    changeIsShowButtonPrev,
    navigate,
    selectedBenefit,
    trackEvent,
  ])

  const closeModal = useCallback(
    () => setInfoModal((oldValue) => ({ ...oldValue, isOpenModal: false })),
    [],
  )

  const openModalEditBalance = useCallback(
    (assignmentId: string) => {
      const assignment = simpleAssignmentsItems.current.find(
        ({ id }) => id === assignmentId,
      )

      const valueTransform =
        assignment?.value.replace(/\./g, '').replace(',', '.') ?? '0'

      setInfoModal({
        assignmentId,
        isOpenModal: true,
        typeModal: ModalBenefitAssignmentsType.EDIT_BALANCE,
        timeUnitLabel: `Saldo por ${
          selectedBenefit?.depositTimeUnit === 'day' ? 'dia' : 'mês'
        }`,
        value: parseFloat(valueTransform),
      })

      trackEvent({ name: 'FlashOS_SimpleAssignment_EditAssign_Click' })
    },
    [selectedBenefit?.depositTimeUnit, trackEvent],
  )

  const openModalExcludeBeneficiary = useCallback(
    (assignmentId: string) => {
      setInfoModal({
        assignmentId,
        isOpenModal: true,
        typeModal: ModalBenefitAssignmentsType.EXCLUDE_BENEFICIARY,
      })

      trackEvent({ name: 'FlashOS_SimpleAssignment_DeleteAssign_Click' })
    },
    [trackEvent],
  )

  useDebounce(
    () => {
      resetPagination()
      setDebouncedSearchTerm(searchTerm)
    },
    500,
    [searchTerm],
  )

  const {
    data: simpleAssignments = { count: 0, items: [] },
    isFetched,
    refetch,
  } = trpc.getBenefitAssignments.useQuery(
    {
      benefitId: selectedBenefit?.id ?? '',
      page: pagination.page,
      pageSize: pagination.pageSize,
      employeeSearch: debouncedSearchTerm,
      sortBy: 'createdAt',
      sortType: 'DESC',
    },
    {
      enabled: !!selectedBenefit?.id,
    },
  )

  useEffect(() => {
    refetch()
  }, [pagination, refetch])

  useEffect(() => {
    if (selectedBenefit?.id) {
      resetPagination()
      setSearchTerm('')
    }
  }, [resetPagination, selectedBenefit?.id])

  useEffect(() => {
    simpleAssignmentsItems.current = simpleAssignments.items
  }, [simpleAssignments.items])

  return {
    simpleAssignments,
    benefitName: selectedBenefit?.name,
    isLoading: !isFetched,
    pagination,
    searchTerm,
    infoModal,
    handleSearchChange,
    navigateToNewAssignment,
    setPagination,
    openModalEditBalance,
    openModalExcludeBeneficiary,
    closeModal,
  }
}
