import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import formatCurrency from '../formatCurrency'

export const useCurrencyInput = (initialValueInCents?: number) => {
  const [valueInCurrency, setValueInCurrency] = useState('')
  const [valueInCents, handleValueInCents] = useState(initialValueInCents ?? 0)

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value

      inputValue = inputValue.replace(/[^\d]/g, '') || '0'

      const newValueInCents = parseInt(inputValue, 10)

      const formatted = formatCurrency(newValueInCents)

      handleValueInCents(newValueInCents)
      setValueInCurrency(formatted)
    },
    [],
  )

  const setValueInCents = useCallback((value: number) => {
    setValueInCurrency(formatCurrency(value))
    handleValueInCents(value)
  }, [])

  const resetValue = useCallback(() => {
    setValueInCurrency('')
    handleValueInCents(0)
  }, [])

  useEffect(() => {
    if (typeof initialValueInCents === 'number') {
      setValueInCurrency(formatCurrency(initialValueInCents))
    }
  }, [initialValueInCents])

  return {
    valueInCurrency,
    valueInCents,
    handleInputChange,
    setValueInCents,
    resetValue,
  }
}
