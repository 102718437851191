import { useNewAssignmentStore } from 'src/pages/NewAssignment/useNewAssignmentStore'

import {
  ContainerStyled,
  DescriptionStyled,
  TitleStyled,
  WrapperImageStyled,
  WrapperTextStyled,
} from './styles'

type HeaderProps = {
  id: string
  benefitName: string
  benefitImage: string
}

export function Header({ id, benefitName, benefitImage }: HeaderProps) {
  const { accordionDescription } = useNewAssignmentStore()

  return (
    <ContainerStyled id={id}>
      <WrapperImageStyled>
        <img src={benefitImage} alt={benefitName} />
      </WrapperImageStyled>

      <WrapperTextStyled>
        <TitleStyled variant="headline8">{benefitName}</TitleStyled>
        <DescriptionStyled variant="body4">
          {accordionDescription(id)}
        </DescriptionStyled>
      </WrapperTextStyled>
    </ContainerStyled>
  )
}
