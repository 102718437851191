import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'

export enum PermissionEnum {
  BENEFITS_SIMPLE_ASSIGNMENT_VIEW = 'benefits_simpleassignment_view',
}

type PermissionType = {
  permission: PermissionEnum
}

export function useAdminPermissions({ permission }: PermissionType) {
  const { companies, isAdmin } = usePermissions()
  const companyId = useSelectedCompany()?.selectedCompany?.id

  const companyPermissions =
    companies?.find((company) => company.id === companyId)?.permissions ?? []
  const canView = companyPermissions.includes(permission)

  return { hasPermission: isAdmin || canView }
}
