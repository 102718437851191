import {
  GenericProfilePicture,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'

import { ContainerStyled, DescriptionsStyled } from './styles'

type CellNameProps = {
  name: string
  cpf: string
  image?: string
}

export function CellName({ name, image, cpf }: CellNameProps) {
  return (
    <ContainerStyled>
      <GenericProfilePicture size={40} name={name} source={image} />

      <DescriptionsStyled>
        <Typography variant="body3" className="name">
          {name}
        </Typography>

        <Typography variant="body4" className="cpf">
          <Icons name="IconId" size={16} /> {cpf}
        </Typography>
      </DescriptionsStyled>
    </ContainerStyled>
  )
}
