import {
  Button,
  Icons,
  LinkButton,
  TransferList,
} from '@flash-tecnologia/hros-web-ui-v2'
import * as FocusedPageTemplate from 'src/components/FocusedPageTemplate'

import * as ContentTemplate from '../components/ContentTemplate'
import { ColumnEmployee } from './Columns/ColumnEmployee'
import { ModalConfirmRemoveEmployee } from './ModalConfirmRemoveEmployee'
import { RightPanelStyled, TransferListContainerStyled } from './styles'
import { EmployeeDataType, useSelectEmployees } from './useSelectEmployees'

export function SelectEmployees() {
  const {
    selectedEmployees,
    selectedEmployeesTotalCount,
    availableEmployees,
    availableEmployeesTotalCount,
    isLoading,
    onCheckItem,
    onSearch,
    onTransfer,
    trackAndNavigateToNextPage,
    trackAndNavigateToCancel,
    navigateToPreviousPage,
    canNavigateToNextPage,
    handleCloseModalRemoveEmployee,
    handleConfirmModalRemoveEmployee,
    isShowModalRemoveEmployee,
    isShowButtonPrev,
    pageTitle,
  } = useSelectEmployees()

  return (
    <>
      <FocusedPageTemplate.Content>
        <ContentTemplate.Title title={pageTitle} />

        <ContentTemplate.LeftPanel
          title="Adicionar pessoas"
          subtitle="Selecione as pessoas que serão atribuídas"
        />

        <RightPanelStyled>
          <TransferListContainerStyled>
            <TransferList
              columns={[
                {
                  Header: 'Nome',
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  Cell: ({ row: { original: employee } }: any) => (
                    <ColumnEmployee
                      name={employee.name}
                      document={employee.documentFormatted}
                      hasBenefitAssignments={employee.hasBenefitAssignments}
                      key={employee._id}
                    />
                  ),
                  accessor: 'name',
                  disableSortBy: true,
                },
              ]}
              onTransfer={({ leftList, rightList }) =>
                onTransfer(
                  leftList.data as EmployeeDataType[],
                  rightList.data as EmployeeDataType[],
                )
              }
              leftList={{
                data: availableEmployees,
                total: availableEmployees.length,
                onCheck: ({ data, allChecked }) => {
                  onCheckItem(
                    data as EmployeeDataType[],
                    allChecked,
                    'available',
                  )
                },
                loading: isLoading,
                onSearch: (text) => onSearch(text, 'available'),
                title: `Todas as pessoas (${availableEmployeesTotalCount})`,
                emptyStageMessage: 'Nenhum colaborador encontrado',
              }}
              rightList={{
                data: selectedEmployees,
                total: selectedEmployees.length,
                onCheck: ({ data, allChecked }) => {
                  onCheckItem(
                    data as EmployeeDataType[],
                    allChecked,
                    'selected',
                  )
                },
                emptyStageMessage: 'Nenhum colaborador encontrado',
                onSearch: (text) => onSearch(text, 'selected'),
                title: `Selecionados para a atribuição (${selectedEmployeesTotalCount})`,
              }}
            />
          </TransferListContainerStyled>
        </RightPanelStyled>
      </FocusedPageTemplate.Content>

      <FocusedPageTemplate.NavFooter
        leftPositionElements={[
          <LinkButton
            variant="secondary"
            onClick={trackAndNavigateToCancel}
            key="cancel-button"
          >
            Cancelar
          </LinkButton>,
        ]}
        rightPositionElements={[
          isShowButtonPrev && (
            <Button
              size="large"
              variant="secondary"
              onClick={navigateToPreviousPage}
              key="back-button"
            >
              <Icons name="IconArrowLeft" /> Voltar
            </Button>
          ),
          <Button
            size="large"
            variant="primary"
            onClick={trackAndNavigateToNextPage}
            key="continue-button"
            disabled={!canNavigateToNextPage}
          >
            Próximo <Icons name="IconArrowRight" />
          </Button>,
        ]}
      />

      <ModalConfirmRemoveEmployee
        isOpen={isShowModalRemoveEmployee}
        onConfirm={handleConfirmModalRemoveEmployee}
        onClose={handleCloseModalRemoveEmployee}
      />
    </>
  )
}
