import { NotificationCard, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Space } from 'src/components/Space'
import { useTheme } from 'styled-components'

import { StyledContainer, StyledLinkButton } from './styled'

const NOTIFICATION_CARD_LS_KEY = 'closed-assign-flash-benefit-warning-card'

export const AssignFlashBenefitNotificationCard = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const hasUserClosedNotificationBefore = getFromLS(NOTIFICATION_CARD_LS_KEY)

  const [isNotificationCardVisible, setIsNotificationCardVisible] = useState(
    !hasUserClosedNotificationBefore,
  )

  return isNotificationCardVisible ? (
    <>
      <NotificationCard
        type="info"
        iconName="IconInfoCircle"
        onClose={() => {
          setIsNotificationCardVisible(false)
          setInLS({
            key: NOTIFICATION_CARD_LS_KEY,
            value: true,
          })
        }}
        closeButton
      >
        <StyledContainer>
          <Typography
            variant="body3"
            variantColor={theme.colors.feedback.info[10]}
            weight={600}
          >
            Para atribuir Benefícios Flash, acesse a página de Pedido de
            Benefícios
          </Typography>
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[30]}
            weight={600}
          >
            Estamos trabalhando para unificar as experiências, no momento nesta
            seção é possível atribuir apenas Benefícios Externos.
          </Typography>
          <Space y="xs2" />
          <StyledLinkButton
            variant="primary"
            onClick={() => navigate('/benefits/dashboard/company/employees')}
            aria-label="Ir para Pedido de Benefícios"
          >
            <span>Ir para Pedido de Benefícios</span>
          </StyledLinkButton>
        </StyledContainer>
      </NotificationCard>
      <Space y="s" />
    </>
  ) : (
    <></>
  )
}
