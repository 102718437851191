import {
  GenericProfilePicture,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'

import {
  EmployeeInfoContainerStyled,
  EmployeeItemContainerStyled,
  WarningTextStyled,
} from './styles'

type ColumnEmployeeProps = {
  name: string
  document: string
  hasBenefitAssignments: boolean
  image?: string | undefined
}

export function ColumnEmployee({
  name,
  document,
  hasBenefitAssignments,
  image = undefined,
}: ColumnEmployeeProps) {
  const renderBenefitWarning = useMemo(() => {
    return (
      <WarningTextStyled variant="body4">
        <Icons name="IconInfoCircle" size={16} /> Benefício já atribuído
      </WarningTextStyled>
    )
  }, [])

  const renderDocument = useMemo(() => {
    return (
      <Typography variant="body4">
        <Icons name="IconId" size={16} /> {document}
      </Typography>
    )
  }, [document])

  return (
    <EmployeeItemContainerStyled>
      <GenericProfilePicture size={40} name={name} source={image} />

      <EmployeeInfoContainerStyled>
        <Typography variant="body3">{name}</Typography>

        {hasBenefitAssignments ? renderBenefitWarning : renderDocument}
      </EmployeeInfoContainerStyled>
    </EmployeeItemContainerStyled>
  )
}
