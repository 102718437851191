import { ReactNode } from 'react'

import { TitleStyled } from './styles'

type TitleProps = {
  children: ReactNode
}

export function Title({ children }: TitleProps) {
  return <TitleStyled variant="body3">{children}</TitleStyled>
}
