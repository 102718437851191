import 'simplebar-react/dist/simplebar.min.css'
import '../src/i18n'

import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2'
import { QueryClientProvider } from '@tanstack/react-query'

import { queryClient, trpc, trpcClient } from './api/client'
import AppRouter from './routes'

export default function Root() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AppRouter />
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  )
}
