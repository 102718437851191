import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 10px;
`

export const DescriptionsStyled = styled.div`
  display: flex;
  flex-direction: column;

  p:first-child {
    font-weight: 600;

    color: ${({ theme }) => theme.colors.neutral[40]};
  }

  p:last-child {
    display: inline-flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.neutral[60]};

    column-gap: ${({ theme }) => theme.spacings.xs5};
  }
`
