import {
  LinkButton,
  Modal,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  row-gap: ${({ theme }) => theme.spacings.xs2};
`

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  max-width: 420px;
`

export const DescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const TextFieldStyled = styled(TextField)`
  width: 320px;
`

export const ButtonCancelStyled = styled(LinkButton)`
  align-self: center !important; // TODO needs to be adjusted in the future using DS
`
