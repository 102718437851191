import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'
import { useIsSimpleAssignmentEnabled } from 'src/utils/hooks/useIsSimpleAssignmentEnabled'
import { useToast } from 'src/utils/hooks/useToast'

export const useFlashBenefitTab = () => {
  const { trackEvent, trackPage } = useEventTracking()
  const { isSimpleAssignmentEnabled } = useIsSimpleAssignmentEnabled()
  const { toastWarning } = useToast()
  const navigate = useNavigate()

  const navigateToNewAssignment = () => {
    trackEvent({ name: 'FlashOS_SimpleAssignment_NewAssignment_Click' })

    if (isSimpleAssignmentEnabled) {
      navigate('/simple-assignment/new-assignment')
    } else {
      toastWarning({
        title:
          'Infelizmente, ainda não é possível atribuir Benefícios Flash por aqui',
        description:
          'Em breve essa funcionalidade estará disponível. Mas por enquanto você pode realizar essa ação na página de Pedido de Benefícios',
      })
    }
  }

  useEffect(() => {
    trackPage({ name: 'FlashOS_SimpleAssignment_View' })
  }, [trackPage])

  return {
    navigateToNewAssignment,
  }
}
