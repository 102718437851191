import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;

  column-gap: 24px;
`

export const WrapperImageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;

  background-color: ${({ theme }) => theme.colors.neutral90};

  border-radius: ${({ theme }) => theme.borders.radius.circular};

  img {
    width: 24px;
    height: 24px;
  }
`

export const WrapperTextStyled = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.xs5};
`

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral30};
`

export const DescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral50};
`
